import { Box, Button, Grid, useMediaQuery, useTheme } from '@mui/material';
import { heroBannerDesktop, heroBannerMobile } from '../../Images';
import styles from './HeroBanner.module.scss';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { NavHashLink } from 'react-router-hash-link';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import VideoPlayer from '../VideoPlayer/VideoPlayer';

const HeroBanner = () => {
  const location = useLocation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);
  const hideComponent = location.pathname !== '/';
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [height]);

  return (
    <>
      <VideoPlayer open={open} setOpen={setOpen} />
      {!hideComponent && (
        // <Grid container sx={{ mt: { md: '-6%', lg: '-7%' } }}>
        <Grid container sx={{ mt: { md: '0%', lg: '0%', height: '100vh' }, cursor: 'pointer' }}>
          <div
            style={{ maxHeight: `${height}px`, display: 'flex', alignItems: 'end' }}
            onClick={() => {
              setOpen(true);
            }}
          >
            {mobile ? (
              <img src={heroBannerMobile} alt='' width='100%' />
            ) : (
              <video autoPlay muted width={'100%'} height='100%' loop className={styles['banner-video']}>
                <source src={heroBannerDesktop} type='video/mp4' />
              </video>
            )}
          </div>
          <Box className={styles['banner-see-more']} sx={{ mt: { md: '-7%', xs: '-30%', sm: '-30%' } }}>
            <NavHashLink smooth to={'/#header'} className={styles['banner-hask-link']}>
              <Button className={styles['see-more-btn']}>See more</Button>
              <ArrowDropDownIcon sx={{ mt: '-22px', fontSize: '50px' }} />
            </NavHashLink>
          </Box>
        </Grid>
      )}
    </>
  );
};

export default HeroBanner;
