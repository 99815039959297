const PageNotFound = () => {
  return (
    <img
      src='https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/404.png?alt=media&token=694bda6c-f532-4c5a-bbb0-2700543d1bcc'
      width='100%'
      alt='page not found'
    />
  );
};

export default PageNotFound;
