import { delivery, meeting, moodboard, projectDeck, projectPlan, setWalk, twoTouch, wrapUp } from '../Images';

export const timelineData = [
  {
    title: 'start a project',
    subSteps: []
  },
  {
    title: 'onboarding',
    subSteps: [
      {
        title: 'discovery',
        desc: 'Once you submit the "Start a Project" form, we schedule a call to discuss your requirements and assess our compatibility for collaboration. During this call, we conduct a comprehensive review of your project to gather all pertinent details. Following this, our team engages in internal discussions to provide you with a tailored budget.',
        media: meeting
      },
      {
        title: 'project deck',
        desc: 'Upon budget approval, we meticulously analyze your brief and perform thorough market research to generate optimal ideas for your project. This results in a project deck that outlines our operational framework, including a detailed timeline with key milestones and your specific requirements. Our goal is to ensure complete alignment throughout the project.',
        media: projectDeck
      }
    ]
  },
  {
    title: 'pre-production',
    subSteps: [
      {
        title: 'moodboard',
        desc: 'Your brief takes visual form through our moodboards. These encapsulate discussions around elements such as color palettes, set design, and lighting, giving you a glimpse of the envisioned result. We establish a definitive creative trajectory, outlining our vision for the project.',
        media: moodboard
      },
      {
        title: 'project plan approval',
        desc: 'Before commencing the shoot, we finalize all moodboard designs and develop a comprehensive shooting plan. This strategy serves as a roadmap for a successful shoot, ensuring alignment among all involved parties.',
        media: projectPlan
      }
    ]
  },
  {
    title: 'production',
    subSteps: [
      {
        title: 'set walkthrough',
        desc: 'We invite you to join us on set, either in person or online, depending on the project’s requirements. We provide real-time updates to ensure everything is proceeding as expected, keeping you informed every step of the way.',
        media: setWalk
      },
      {
        title: 'wrap up update',
        desc: 'After the shoot, we deliver a wrap-up report detailing how everything went and discuss the next stages of the project.',
        media: wrapUp
      }
    ]
  },
  {
    title: 'post-production',
    subSteps: [
      {
        title: 'revisions',
        desc: 'During the editing process, we provide you with a refined draft. You have the opportunity to request up to two sets of revisions to ensure the content meets the highest standards and aligns with your specifications.',
        media: twoTouch
      },
      {
        title: 'Final delivery',
        desc: 'Finally, we present the completed product and finalize all essential project closure procedures. We are dedicated to bringing your creative vision to life through a transparent and collaborative process.',
        media: delivery
      }
    ]
  }
];
