import { useMediaQuery, useTheme } from '@mui/material';
import TimeLine from '../../components/Timeline/TimeLine';
import TimeLineMobile from '../../components/Timeline/TimeLineMobile';
import { Helmet } from 'react-helmet';

const Process = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Helmet>
        <meta name='title' content='Our Process | MUDROST - Photography & Videography' />
        <meta
          name='description'
          content='MUDROST’s clear and simple process from onboarding to delivery. We ensure smooth communication and high-quality results with every step.'
        />
        <meta name='keywords' content='Photo and Videography ' />
      </Helmet>
      {mobile ? <TimeLineMobile /> : <TimeLine />}
    </>
  );
};

export default Process;
