import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import styles from './Timeline.module.scss';
import { Link } from 'react-router-dom';
import { Box, Button, Card, CardMedia, Grid } from '@mui/material';
import { delivery, meeting, moodboard, projectDeck, projectPlan, setWalk, twoTouch, wrapUp } from '../../Images';

export default function TimeLine() {
  return (
    <>
      <Grid container sx={{ p: { xs: '30px 30px 0 30px', sm: '30px 30px 0 30px', md: '40px 40px 0 40px' } }}>
        <Grid item sm={12} xs={12}>
          <Typography variant='h2' sx={{ pb: '10px' }}>
            process
          </Typography>
          <Typography variant='body1'>A breakdown of our project timeline</Typography>
        </Grid>
      </Grid>
      <Timeline position='alternate' className={styles['timeline-container']}>
        <TimelineItem>
          <TimelineSeparator>
            <Typography variant='h3' className={styles['start-project']}>
              start a project
            </Typography>
            <TimelineConnector sx={{ backgroundColor: '#000' }} />
          </TimelineSeparator>
          <TimelineContent></TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineSeparator>
            <Typography variant='h3' className={styles['timeline-heading']}>
              onboarding
            </Typography>
            <TimelineConnector sx={{ ml: { sm: '1px', xs: '1px', md: 0 }, backgroundColor: '#000' }} />
          </TimelineSeparator>
          <TimelineContent></TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent sx={{ m: 'auto 0' }} align='right' variant='body2' color='text.secondary'>
            {/* <FastfoodIcon /> */}
            <Box>
              <Card className={styles['timeline-gif-right']}>
                <CardMedia component={'img'} image={meeting} alt={'Food & Beverages Photographer in Bangalore'} sx={{ width: { md: '50%' } }} />
              </Card>
            </Box>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector sx={{ flexGrow: { md: '0.17', sm: '0.04', xs: '0.04' }, backgroundColor: '#000' }} />
            <TimelineDot sx={{ backgroundColor: '#000' }}></TimelineDot>
            <TimelineConnector sx={{ backgroundColor: '#000' }} />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant='h6' component='span' sx={{ fontSize: '18px' }}>
              discovery
            </Typography>
            <Typography className={styles['timline-detail']}>
              Once you submit the "Start a Project" form, we schedule a call to discuss your requirements and assess our compatibility for collaboration. During
              this call, we conduct a comprehensive review of your project to gather all pertinent details. Following this, our team engages in internal
              discussions to provide you with a tailored budget.
            </Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent sx={{ m: 'auto 0' }} align='right' variant='body2' color='text.secondary'>
            <Box>
              <Card className={styles['timeline-gif-left']}>
                <CardMedia component={'img'} image={projectDeck} alt={'Food & Beverages Photographer in Bangalore'} sx={{ width: { md: '50%' } }} />
              </Card>
            </Box>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector sx={{ flexGrow: { md: '0.17', sm: '0.04', xs: '0.04' }, backgroundColor: '#000' }} />
            <TimelineDot sx={{ backgroundColor: '#000' }}>{/* <FastfoodIcon /> */}</TimelineDot>
            <TimelineConnector sx={{ backgroundColor: '#000' }} />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }} className={styles['timeline-left-detail']}>
            <Typography variant='h6' component='span' sx={{ fontSize: '18px' }}>
              project deck
            </Typography>
            <Typography className={styles['timeline-detail-right']}>
              Upon budget approval, we meticulously analyze your brief and perform thorough market research to generate optimal ideas for your project. This
              results in a project deck that outlines our operational framework, including a detailed timeline with key milestones and your specific
              requirements. Our goal is to ensure complete alignment throughout the project.
            </Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineSeparator>
            <TimelineConnector sx={{ backgroundColor: '#000' }} />
          </TimelineSeparator>
          <TimelineContent></TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineSeparator>
            <Typography variant='h3' className={styles['timeline-heading']}>
              pre-production
            </Typography>
            <TimelineConnector sx={{ backgroundColor: '#000' }} />
          </TimelineSeparator>
          <TimelineContent></TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent sx={{ m: 'auto 0' }} align='right' variant='body2' color='text.secondary'>
            <Box>
              <Card className={styles['timeline-gif-right']}>
                <CardMedia component={'img'} image={moodboard} alt={'Food & Beverages Photographer in Bangalore'} sx={{ width: { md: '50%' } }} />
              </Card>
            </Box>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector sx={{ flexGrow: { md: '0.17', sm: '0.04', xs: '0.04' }, backgroundColor: '#000' }} />
            <TimelineDot sx={{ backgroundColor: '#000' }}>{/* <FastfoodIcon /> */}</TimelineDot>
            <TimelineConnector sx={{ backgroundColor: '#000' }} />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant='h6' component='span' sx={{ fontSize: '18px' }}>
              moodboard
            </Typography>
            <Typography className={styles['timline-detail']}>
              Your brief takes visual form through our moodboards. These encapsulate discussions around elements such as color palettes, set design, and
              lighting, giving you a glimpse of the envisioned result. We establish a definitive creative trajectory, outlining our vision for the project.
            </Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent sx={{ m: 'auto 0' }} align='right' variant='body2' color='text.secondary'>
            <Box>
              <Card className={styles['timeline-gif-left']}>
                <CardMedia component={'img'} image={projectPlan} alt={'banProduct Photography in BangalorenerAltTxt'} sx={{ width: { md: '50%' } }} />
              </Card>
            </Box>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector sx={{ flexGrow: { md: '0.17', sm: '0.04', xs: '0.04' }, backgroundColor: '#000' }} />
            <TimelineDot sx={{ backgroundColor: '#000' }}>{/* <FastfoodIcon /> */}</TimelineDot>
            <TimelineConnector sx={{ backgroundColor: '#000' }} />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }} className={styles['timeline-left-detail']}>
            <Typography variant='h6' component='span' sx={{ fontSize: '18px' }}>
              project plan approval
            </Typography>
            <Typography className={styles['timline-detail']}>
              Before commencing the shoot, we finalize all moodboard designs and develop a comprehensive shooting plan. This strategy serves as a roadmap for a
              successful shoot, ensuring alignment among all involved parties.
            </Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineSeparator>
            <TimelineConnector sx={{ backgroundColor: '#000' }} />
          </TimelineSeparator>
          <TimelineContent></TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineSeparator>
            <Typography variant='h3' className={styles['timeline-heading']}>
              production
            </Typography>
            <TimelineConnector sx={{ ml: '1px', backgroundColor: '#000' }} />
          </TimelineSeparator>
          <TimelineContent></TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent sx={{ m: 'auto 0' }} align='right' variant='body2' color='text.secondary'>
            <Box>
              <Card className={styles['timeline-gif-right']}>
                <CardMedia component={'img'} image={setWalk} alt={'Product Photography in Bangalore'} sx={{ width: { md: '50%' } }} />
              </Card>
            </Box>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector sx={{ flexGrow: { md: '0.17', sm: '0.04', xs: '0.04' }, backgroundColor: '#000' }} />
            <TimelineDot sx={{ backgroundColor: '#000' }}>{/* <FastfoodIcon /> */}</TimelineDot>
            <TimelineConnector sx={{ backgroundColor: '#000' }} />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant='h6' component='span' sx={{ fontSize: '18px' }}>
              set walkthrough
            </Typography>
            <Typography className={styles['timline-detail']}>
              We invite you to join us on set, either in person or online, depending on the project’s requirements. We provide real-time updates to ensure
              everything is proceeding as expected, keeping you informed every step of the way.
            </Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent sx={{ m: 'auto 0' }} align='right' variant='body2' color='text.secondary'>
            <Box>
              <Card className={styles['timeline-gif-left']}>
                <CardMedia component={'img'} image={wrapUp} alt={'Photography & Videography'} sx={{ width: { md: '50%' } }} />
              </Card>
            </Box>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector sx={{ flexGrow: { md: '0.17', sm: '0.04', xs: '0.04' }, backgroundColor: '#000' }} />
            <TimelineDot sx={{ backgroundColor: '#000' }}>{/* <FastfoodIcon /> */}</TimelineDot>
            <TimelineConnector sx={{ backgroundColor: '#000' }} />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }} className={styles['timeline-left-detail']}>
            <Typography variant='h6' component='span' sx={{ fontSize: '18px' }}>
              wrap-up update
            </Typography>
            <Typography className={styles['timline-detail']}>
              After the shoot, we deliver a wrap-up report detailing how everything went and discuss the next stages of the project.
            </Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineSeparator>
            <TimelineConnector sx={{ backgroundColor: '#000' }} />
          </TimelineSeparator>
          <TimelineContent></TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineSeparator>
            <Typography variant='h3' className={styles['timeline-heading']}>
              post-production
            </Typography>
            <TimelineConnector sx={{ ml: '1px', backgroundColor: '#000' }} />
          </TimelineSeparator>
          <TimelineContent></TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent sx={{ m: 'auto 0' }} align='right' variant='body2' color='text.secondary'>
            <Box>
              <Card className={styles['timeline-gif-right']}>
                <CardMedia component={'img'} image={twoTouch} alt={'Photography & Videography'} sx={{ width: { md: '50%' } }} />
              </Card>
            </Box>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector sx={{ flexGrow: { md: '0.17', sm: '0.04', xs: '0.04' }, backgroundColor: '#000' }} />
            <TimelineDot sx={{ backgroundColor: '#000' }}>{/* <FastfoodIcon /> */}</TimelineDot>
            <TimelineConnector sx={{ backgroundColor: '#000' }} />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant='h6' component='span' sx={{ fontSize: '18px' }}>
              revisions
            </Typography>
            <Typography className={styles['timline-detail']}>
              During the editing process, we provide you with a refined draft. You have the opportunity to request up to two sets of revisions to ensure the
              content meets the highest standards and aligns with your specifications.
            </Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent sx={{ m: 'auto 0' }} align='right' variant='body2' color='text.secondary'>
            <Box>
              <Card className={styles['timeline-gif-left']}>
                <CardMedia component={'img'} image={delivery} alt={'PRODUCT PHOTOGRAPHY '} sx={{ width: { md: '50%' } }} />
              </Card>
            </Box>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector sx={{ flexGrow: { md: '0.17', sm: '0.04', xs: '0.04' }, backgroundColor: '#000' }} />
            <TimelineDot sx={{ backgroundColor: '#000' }}>{/* <FastfoodIcon /> */}</TimelineDot>
            <TimelineConnector sx={{ backgroundColor: '#000' }} />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }} className={styles['timeline-left-detail']}>
            <Typography variant='h6' component='span' sx={{ fontSize: '18px' }}>
              final delivery
            </Typography>
            <Typography className={styles['timline-detail']}>
              Finally, we present the completed product and finalize all essential project closure procedures. We are dedicated to bringing your creative vision
              to life through a transparent and collaborative process.
            </Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineSeparator>
            <TimelineConnector sx={{ backgroundColor: '#000' }} />
          </TimelineSeparator>
          <TimelineContent></TimelineContent>
        </TimelineItem>

        <Box xs={12} sm={12} sx={{ mt: 8 }}>
          <Typography sx={{ textAlign: 'center', color: '#000' }}>Together, let's mould something truly remarkable.</Typography>
        </Box>
        <TimelineItem>
          <TimelineSeparator sx={{ mt: 5 }}>
            <Link to='/faq' style={{ textDecoration: 'none', color: '#000' }}>
              {/* <Typography variant='h3' className={styles['timeline-heading']}>
                know more
              </Typography> */}
              <Button
                color='primary'
                variant='contained'
                fullWidth
                type='submit'
                className={styles['timeline-knowmore-btn']}
                sx={{ width: 'max-content', fontSize: '20px' }}
              >
                know more
              </Button>
            </Link>

            <TimelineConnector sx={{ ml: '1px' }} />
          </TimelineSeparator>
          <TimelineContent></TimelineContent>
        </TimelineItem>
      </Timeline>
    </>
  );
}
