import { config } from './config';
const { mediaBaseurl, mediaQueryString } = config;
export const homeBanner1 = `${mediaBaseurl}homepage_h1.jpg${mediaQueryString}`;
export const homepage_v2 = `${mediaBaseurl}homepage_v2.jpg${mediaQueryString}`;
export const homepage_h4 = `${mediaBaseurl}homepage_h4.jpg${mediaQueryString}`;
export const homepage_sq1 = `${mediaBaseurl}homepage_sq1.jpg${mediaQueryString}`;
export const homepage_v1 = `${mediaBaseurl}homepage_v1.jpg${mediaQueryString}`;
// Hero banner
export const heroBannerMobile =
  // 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/videos%2Fmobile_version_landing.mp4?alt=media&token=19a41a0c-0925-41c9-b1ff-e06f46610921';
  'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/videos%2Fgif_mobile_version%20.gif?alt=media&token=76332284-5bca-4dc2-8ed3-837a7c34b11a';
export const heroBannerShowReel =
  'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/videos%2FMudrost%20showreel.mp4?alt=media&token=62909f08-8501-46f9-937c-6f30683c4fe5';
export const showReelPoster =
  'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/videos%2Fshowreel_banner.jpg?alt=media&token=84de32bc-233c-441b-9ed7-b5343ce04e54';
export const bildLogo =
  'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/logos%2FMudrost_logo.png?alt=media&token=7d57b9ca-a69a-4414-b290-30c11ac689e6';
export const heroBannerDesktop =
  'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/videos%2Fweb_version_landing.mp4?alt=media&token=e0feef58-4bf0-4248-bcd6-a9bf6d2a0925';
// 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/videos%2Fmudrsot_landing_page.mp4?alt=media&token=cf613476-e163-42b0-a33f-7b9953ae6f1f';
export const bannerVideoWithoutLogo =
  'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/videos%2Fbild_landing_page_video.mp4?alt=media&token=dc908b54-a28c-47f6-b712-975a4588ec5f';
export const behnaceBlack =
  'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/logos%2Fbehnace_black.svg?alt=media&token=b511bf39-b6c8-419e-b36a-a5f30892ba64';
export const pintrestBlack =
  'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/logos%2Fpintrest%20black%20icon.svg?alt=media&token=497d946c-bdf4-491e-b4e9-d0d2f30d049a';
export const instagramBlack =
  'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/logos%2Finstagram_black.svg?alt=media&token=8d53557c-a411-4c49-8215-16e31479f3e8';
export const linkedinBlack =
  'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/logos%2Flinkedin_black.svg?alt=media&token=88593d7b-f048-4396-ac1d-7dfcb735289b';
export const whatsappBlack =
  'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/logos%2Fwhatsapp_black.svg?alt=media&token=72c1ecd2-6c95-4752-900e-5f05257f0c56';

// process page icons
export const meeting =
  'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/process%2Fmeeting.gif?alt=media&token=959430fe-497d-4641-a61f-3a6babf82b9d';
export const projectDeck =
  'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/process%2Fproject_deck.gif?alt=media&token=e131f5ef-dc12-4fef-8f07-9145b087b621';
export const moodboard =
  'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/process%2Fmoodboard.gif?alt=media&token=c8b360fb-7d4d-4693-8449-533f1c7fcf50';
export const projectPlan =
  'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/process%2Fproduction_plan.gif?alt=media&token=2bbe3f61-35d3-4945-9197-a8b238e8f16e';
export const setWalk =
  'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/process%2Fset_walkthrough.gif?alt=media&token=79eb7818-37ea-4f64-9d0d-31b3931e8361';
export const wrapUp =
  'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/process%2Fwrap_up_update.gif?alt=media&token=e2e6563b-4b1f-4d6a-8875-0947d58c44b2';
export const twoTouch =
  'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/process%2F2_revision.gif?alt=media&token=7a69bb83-32b8-4d19-a464-6e79ea2996e5';
export const delivery =
  'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/process%2Fdelivery.gif?alt=media&token=8a8e0c9e-19d5-4415-98dd-be3cb73b71c9';
export const arrow =
  'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/logos%2Farrow.svg?alt=media&token=ee6b7cbe-56e1-4eca-8e61-77ba1a46add3';
// blog icons

export const next =
  'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/icons%2Fnext.png?alt=media&token=382ce432-3a26-483f-9fb1-8346f90ba4e8';
export const noNext =
  'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/icons%2Fnonext.png?alt=media&token=cb5ac461-ef98-407d-8d67-b8a94b7c527f';
export const noPrev =
  'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/icons%2Fnoprev.png?alt=media&token=51154afe-b4ad-475f-9d20-eed6f7ecf10f';
export const prev =
  'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/icons%2Fprev.png?alt=media&token=e098a909-5379-4465-a426-265bcc1652a3';

// contact page

export const contact =
  'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/contact.png?alt=media&token=2773b09a-bd4f-4941-b2f9-62bc9190fe0d';
export const callIcon =
  'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/icons%2Fcall_icon.svg?alt=media&token=ba49037a-dacc-4b9b-983f-cd07234414ea';
export const mailIcon =
  'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/icons%2Fmail_icon.svg?alt=media&token=3766f2c9-0937-4d57-8979-502275b933c5';
export const locationIcon =
  'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/icons%2Flocation_icon.svg?alt=media&token=b0b9f9f1-78ef-4e52-853e-1597ebd1d3fd';
