import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import styles from './Footer.module.scss';
import { behnaceBlack, pintrestBlack, linkedinBlack, instagramBlack, whatsappBlack } from '../../Images';
import { Link } from 'react-router-dom';

const Footer = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Grid container className={styles['footer-container']}>
        <Grid item md={8} xs={12} sx={{ display: 'flex' }} className={styles['footer-email']}>
          <Box sx={{ mr: '20px' }}>
            <Typography variant='h6'>
              <Link to={'/contact'}>contact</Link>
            </Typography>
          </Box>
          <div>
            <Typography variant='h6' sx={{ mr: '20px' }}>
              <Link to={'/faq'}>FAQ</Link>
            </Typography>
          </div>
        </Grid>
        {/* <Grid item md={7} className={styles["footer-email"]}></Grid> */}
        <Grid
          item
          md={4}
          xs={12}
          className={styles['footer-social-icons']}
          sx={{ paddingTop: { xs: '10px', sm: '10px' }, textAlign: { xs: 'left', md: 'right' } }}
        >
          <a href='https://wa.me/918431014505' target='blank'>
            <img src={whatsappBlack} alt='whatsapp' width={mobile ? '14.5%' : '8%'} />
          </a>
          <a href='https://www.instagram.com/mudroststudio/' target='blank'>
            <img src={instagramBlack} alt='instagram' width={mobile ? '14.5%' : '8%'} />
          </a>
          <a href='https://www.behance.net/mudroststudio' target='blank'>
            <img src={behnaceBlack} alt='behnace' width={mobile ? '14.5%' : '8%'} />
          </a>
          <a href='https://www.linkedin.com/company/mudroststudio/posts/?feedView=all' target='blank'>
            <img src={linkedinBlack} alt='linkedin' width={mobile ? '14.5%' : '8%'} />
          </a>
          <a href='https://in.pinterest.com/mudroststudio/' target='blank'>
            <img src={pintrestBlack} alt='pintrest' width={mobile ? '14.5%' : '8%'} />
          </a>
        </Grid>
      </Grid>
    </>
  );
};

export default Footer;
