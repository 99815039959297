import * as React from 'react';
import { Box, Card, CardMedia, Grid, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import { services } from '../../siteData/Services';
import { Helmet } from 'react-helmet';

const RenderMedia = ({ mediaObj }) => {
  const { thumbnail, thumbnailAltText } = mediaObj;
  return (
    <Card raised={false} sx={{ borderRadius: '20px', boxShadow: 'none' }}>
      <CardMedia component='img' image={thumbnail} alt={thumbnailAltText} />
    </Card>
  );
};

const RenderCopy = ({ svcObj }) => {
  const { name, description, bulletHeading, bullets } = svcObj;
  return (
    <Box>
      <Typography variant='body1' sx={{ fontSize: '20px', fontWeight: 'bold', lineHeight: '3 !important' }}>
        {name}
      </Typography>
      {/* <Typography variant='body1' sx={{ fontSize: '17px', fontWeight: 'bold', color: '#764b2d', pb: '20px' }}>
        {price}
      </Typography> */}
      <Typography variant='body1' sx={{ textAlign: 'justify' }}>
        {description}
      </Typography>
      <br />
      <Typography variant='body1'>{bulletHeading}</Typography>
      <ul style={{ paddingLeft: '20px' }}>
        {bullets.map((b, bIndex) => (
          <li key={bIndex}>
            <Typography variant='body1'>{b}</Typography>
          </li>
        ))}
      </ul>
    </Box>
  );
};

const Services = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Helmet>
        <meta name='title' content='Professional Photography and Videography Services By MUDROST' />
        <meta
          name='description'
          content='We provide Four flexible categories designed to meet your unique needs. Find the perfect fit for your project. Let’s create something amazing together!'
        />
        <meta name='keywords' content='Photo and Videography ' />
      </Helmet>
      <Grid container sx={{ p: { xs: '30px 30px 0 30px', sm: '30px 30px 0 30px', md: '40px 40px 0 40px' } }}>
        <Typography variant='h2' sx={{ pb: '10px' }}>
          services
        </Typography>
        <Typography variant='body1'>
          At mudrost, we mould our services to suit your photography and videography needs. Whether you need content for your website, social media or you just
          feel like creating something fresh for your band, we are dedicated to supporting you at every stage. Our solutions are categorized into four segments,
          with the flexibility to be customized based on your specific needs.
        </Typography>
      </Grid>
      <Grid container sx={{ p: { xs: '30px', sm: '30px', md: '40px' } }} spacing={mobile ? 8 : 20}>
        {services.map((s, sIndex) =>
          mobile ? (
            <React.Fragment key={sIndex}>
              <Grid item md={6} sm={12} xs={12}>
                <RenderMedia mediaObj={s} />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <RenderCopy svcObj={s} />
              </Grid>
            </React.Fragment>
          ) : (
            <React.Fragment key={sIndex}>
              {sIndex % 2 === 0 ? (
                <>
                  <Grid item md={6} sm={12} xs={12}>
                    <RenderCopy svcObj={s} />
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <RenderMedia mediaObj={s} />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item md={6} sm={12} xs={12}>
                    <RenderMedia mediaObj={s} />
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <RenderCopy svcObj={s} />
                  </Grid>
                </>
              )}
            </React.Fragment>
          )
        )}
      </Grid>
      <Grid container sx={{ p: { xs: '30px 30px 0 30px', sm: '30px 30px 0 30px', md: '40px 40px 0 40px' } }}>
        <Grid item md={12} sm={12} xs={12} sx={{ textAlign: 'center' }}>
          <Typography variant='body1'>Ready to bring your vision to life? Contact us today to discuss your project!</Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default Services;
