import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Card, CardMedia, Grid, Typography } from '@mui/material';
import { projects } from '../../siteData/Projects';
import ProjectsGrid from '../../components/ProjectsGrid/ProjectsGrid';
import styles from './ProjectDetail.module.scss';
import { Interweave } from 'interweave';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import Counter from 'yet-another-react-lightbox/plugins/counter';
import 'yet-another-react-lightbox/plugins/counter.css';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
// import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
// import 'yet-another-react-lightbox/plugins/thumbnails.css';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';

const Quote = ({ clientQuote }) => {
  const { clientName, designation, quote } = clientQuote;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 4, position: 'relative' }}>
      <Box sx={{ position: 'absolute', top: { xs: '0', sm: '0', md: '-26px' }, img: { width: { xs: '50%', sm: '50%', md: 'auto' } } }}>
        <img
          src='https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/logos%2Fquote_1.png?alt=media&token=a082b52d-07f9-4c3d-80c2-66db4b8599bc'
          alt=''
        />
      </Box>
      <Box sx={{ m: '0 15%' }}>
        <Typography variant='subtitle1' sx={{ fontWeight: 'bold', mt: 2, fontStyle: 'italic' }}>
          {clientName}
        </Typography>
        <Typography variant='subtitle2' sx={{ color: 'gray', fontStyle: 'italic' }}>
          {designation}
        </Typography>
        <Typography variant='h6' sx={{ fontWeight: 'normal' }}>
          <Interweave content={quote} />
        </Typography>
      </Box>
      <Box sx={{ position: 'absolute', bottom: '-60px', right: { xs: '0', sm: '0', md: '40px' }, img: { width: { xs: '50%', sm: '50%', md: 'auto' } } }}>
        <img
          src='https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/logos%2Fquote_2.png?alt=media&token=28fd7390-0c65-4d1f-b65a-daa64508e786'
          alt=''
        />
      </Box>
    </Box>
  );
};

const ProjectDetail = () => {
  const params = useParams();
  const selectedProject = projects.filter((p) => p.id === parseInt(params.id));
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isPreviewOpen, setisPreviewOpen] = useState(false);
  const { title, banner, category, meta, media, id, clientQuote } = selectedProject[0];
  const { isVideo, bannerSrc, bannerAltTxt } = banner;
  // const { clientName, designation, quote } = clientQuote !== undefined ? clientQuote : {};
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    setisPreviewOpen(false);
  }, [params.id]);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setisPreviewOpen(true);
  };
  const mediaWoVideos = media.filter((m) => !m.isVideo);
  const videoCnt = media.length - mediaWoVideos.length;

  return (
    <>
      <Grid container sx={{ p: { xs: '30px', sm: '30px', md: '40px' } }}>
        <Grid item md={12} sx={{ pb: '40px' }}>
          <Card raised={false} sx={{ borderRadius: '20px', boxShadow: 0 }}>
            <CardMedia component={isVideo ? 'video' : 'img'} image={bannerSrc} alt={bannerAltTxt} autoPlay muted={true} loop controls />
          </Card>
        </Grid>

        <Grid item md={4} className={styles['project-details-container']}>
          <Typography variant='h2' sx={{ paddingBottom: '8%' }}>
            {title}
          </Typography>

          {meta &&
            Object.keys(meta).map((key, mIndex) => (
              <div style={{ display: 'flex' }}>
                <Typography
                  variant='body1'
                  sx={{ textAlign: { md: 'justify', sm: key === 'description' ? 'justify' : 'left', xs: key === 'description' ? 'justify' : 'left' } }}
                >
                  <span style={{ fontWeight: 'bold', fontSize: '15px' }}>{key}:&nbsp;&nbsp;</span>
                  {meta[key]}
                </Typography>
              </div>
            ))}
        </Grid>

        <Grid item md={8}>
          <Grid container rowSpacing={4}>
            {media.map(({ isVideo, src, mediaAltTxt }, mIndex) => (
              <Grid item md={12}>
                <Card
                  raised={false}
                  onClick={() => !isVideo && openLightbox(mIndex)}
                  sx={{
                    display: 'flex',
                    justifyContent: 'right',
                    boxShadow: 0,
                    borderRadius: 0,
                    cursor: isVideo ? 'default' : 'pointer'
                  }}
                >
                  <CardMedia component={isVideo ? 'video' : 'img'} image={src} alt={mediaAltTxt} sx={{ width: '100%' }} autoPlay loop muted={true} controls />
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
        {/* client quote */}
        {clientQuote && (
          <>
            <Grid item md={2} sm={12}></Grid>
            <Grid item md={8} sm={12}>
              <Quote clientQuote={clientQuote} />
            </Grid>
            <Grid item md={2} sm={12}></Grid>
          </>
        )}
      </Grid>

      <Lightbox
        plugins={[Counter, Fullscreen, Zoom]}
        inline={{
          style: { width: '100%', maxWidth: '900px', aspectRatio: '3 / 2' }
        }}
        index={photoIndex - videoCnt}
        open={isPreviewOpen}
        close={() => setisPreviewOpen(false)}
        slides={[...mediaWoVideos]}
      />

      {/* Redner the related projects */}
      <ProjectsGrid projects={projects} category={category} excludeId={id} isRelated={true} />
    </>
  );
};

export default ProjectDetail;
