import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import { Grid } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import { faq } from '../../siteData/Faq';
import { Helmet } from 'react-helmet';
import { Interweave } from 'interweave';
const Faqs = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);
  return (
    <>
      <Helmet>
        <meta name='title' content='MUDROST: Fast & Friendly Product Photography - FAQs and Support' />
        <meta
          name='description'
          content='MUDROST is your friendly super fast product photography provider. Ask us anything here or, see the questions people have asked us before.'
        />
        <meta name='keywords' content='PRODUCT PHOTOGRAPHY' />
      </Helmet>
      <Grid container sx={{ p: { xs: '30px 30px 0 30px', sm: '30px 30px 0 30px', md: '40px 40px 0 40px' } }}>
        <Typography variant='h2'>FAQ</Typography>
      </Grid>
      <Grid container sx={{ p: { xs: '30px 30px 0 30px', sm: '30px 30px 0 30px', md: '40px 40px 0 40px' } }}>
        {faq.map(({ question, answer }, fIndex) => (
          <Grid item xs={12}>
            <Accordion expanded={expanded === fIndex} onChange={handleChange(fIndex)} sx={{ boxShadow: 'none' }}>
              <AccordionSummary
                expandIcon={expanded === fIndex ? <RemoveIcon /> : <AddIcon />}
                aria-controls='panel1bh-content'
                sx={{ flexDirection: 'row-reverse' }}
              >
                <Typography sx={{ fontWeight: 'bold' }}>{question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ textAlign: 'justify', width: { md: '75%', sm: '100%', xs: '100%' } }}>
                  <Interweave content={answer} />
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Faqs;
