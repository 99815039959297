import { useNavigate, useParams } from 'react-router-dom';
import { allBlogs } from '../../siteData/Blogs';
import { Box, Button, Card, CardMedia, Grid, Typography } from '@mui/material';
import { Interweave } from 'interweave';
import { useEffect, useState } from 'react';
import { next, noNext, noPrev, prev } from '../../Images';

const BlogDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const selectedBlog = allBlogs.filter((b) => b.id === parseInt(params.id));
  const [isNoPrev, setIsNoPrev] = useState(false);
  const [isNoNext, setIsNoNext] = useState(false);
  const { title, banner, bannerAltTxt, intro1, intro2, details, bottomLine } = selectedBlog[0];

  useEffect(() => {
    window.scrollTo(0, 0);
    const currentId = parseInt(params.id);
    if (currentId === allBlogs.length) {
      setIsNoNext(true);
    }
    if (currentId === 1) {
      setIsNoPrev(true);
    }
  }, [params.id]);

  const handleNext = () => {
    const currentId = parseInt(params.id);
    if (currentId === allBlogs.length) {
      setIsNoNext(true);
    } else {
      setIsNoPrev(false);
      navigate(`/blog/${currentId + 1}`);
    }
  };
  const handlePrev = () => {
    const currentId = parseInt(params.id);
    if (currentId === 1) {
      setIsNoPrev(true);
    } else {
      setIsNoNext(false);
      navigate(`/blog/${currentId - 1}`);
    }
  };
  return (
    <Grid
      container
      sx={{
        p: { xs: '30px', sm: '30px', md: '40px 12%' },
        '& ul': {
          listStyleType: 'disc'
        },
        '& ul li::marker': {
          //   content: '"• "', // Adding content to the ::before pseudo-element
          color: '#000' // Setting the color to red
          //   fontSize: '20px'
        }
      }}
    >
      <Grid item md={12} sx={{ pb: '40px' }}>
        <Typography variant='h3' sx={{ paddingBottom: '1%' }}>
          {title}
        </Typography>
        <Card raised={false} sx={{ borderRadius: '20px', boxShadow: 0 }}>
          <CardMedia component='img' image={banner} alt={bannerAltTxt} />
        </Card>
        <Box sx={{ mt: '20px' }}>
          <Typography variant='body1'>{intro1}</Typography>
        </Box>
        <Box sx={{ mt: '20px' }}>
          <Typography variant='body1'>{intro2}</Typography>
        </Box>
        <Box sx={{ mt: '20px' }}>
          <ol style={{ paddingLeft: 0, marginLeft: '15px' }}>
            {details.map(({ desc, hasMedia, mediaUrl, mediaAltTxt, title, isHighlight }, dIndex) => (
              <Grid container sx={{ pb: '20px' }}>
                {}
                {isHighlight ? (
                  <>
                    <Grid item md={5.5} sx={{ display: 'flex', alignItems: 'center', p: '20px' }}>
                      <Typography variant='h3' sx={{ fontFamily: 'gilroy-bold', fontSize: '30px', lineHeight: '36px', color: '#1a475e', fontStyle: 'italic' }}>
                        {title}
                      </Typography>
                    </Grid>
                    <Grid item md={0.5}></Grid>
                    <Grid item md={5.5}>
                      <Card raised={false} sx={{ borderRadius: '20px', boxShadow: 0, width: { md: '100%', xs: '100%' } }}>
                        <CardMedia component='img' image={mediaUrl} alt={mediaAltTxt} />
                      </Card>
                    </Grid>
                    <Grid item md={0.5}></Grid>
                  </>
                ) : hasMedia ? (
                  <>
                    <Grid item md={3} xs={12} sx={{ display: 'flex', alignItems: 'center', pb: '20px' }}>
                      <Card raised={false} sx={{ borderRadius: '20px', boxShadow: 0, width: '90%' }}>
                        <CardMedia component='img' image={mediaUrl} alt={mediaAltTxt} sx={{}} />
                      </Card>
                    </Grid>
                    <Grid item md={9}>
                      <Typography variant='h6'>
                        <li>{title}</li>
                      </Typography>

                      <Typography variant='body1' sx={{ textAlign: 'justify' }}>
                        <Interweave content={desc} />
                      </Typography>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Typography variant='h6'>
                      <li>{title}</li>
                    </Typography>
                    <Typography variant='body1' sx={{ textAlign: 'justify' }}>
                      <Interweave content={desc} />
                    </Typography>
                  </>
                )}
              </Grid>
            ))}
          </ol>
        </Box>
        <Box sx={{ mt: '20px' }}>
          <Typography variant='body1'>{bottomLine}</Typography>
        </Box>
      </Grid>
      <Grid item md={12} xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          onClick={handlePrev}
          disabled={isNoPrev}
          startIcon={<img src={isNoPrev ? noPrev : prev} alt='custom-icon' width='24' height='24' style={{ display: 'inline-block' }} />}
        ></Button>
        <Button
          onClick={handleNext}
          disabled={isNoNext}
          startIcon={<img src={isNoNext ? noNext : next} alt='custom-icon' width='24' height='24' style={{ display: 'inline-block' }} />}
        ></Button>
      </Grid>
    </Grid>
  );
};

export default BlogDetails;
