import { projects } from '../../siteData/Projects';
import ProjectsGrid from '../../components/ProjectsGrid/ProjectsGrid';
import { Helmet } from 'react-helmet';

const FoodAndBeverages = () => {
  return (
    <>
      <Helmet>
        <meta name='title' content='Food & Beverages Photographer in Bangalore- PICTURE PERFECT STUDIO' />
        <meta
          name='description'
          content='Our expert food & beverage photographers will use their technical skills, artistic eye and marketing mindset to amplify your delicious product.'
        />
        <meta name='keywords' content='Food & Beverages Photographer in Bangalore' />
      </Helmet>
      <ProjectsGrid projects={projects} category={'foodandbeverages'} />
    </>
  );
};

export default FoodAndBeverages;
