import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { heroBannerShowReel, showReelPoster } from '../../Images';
import CloseIcon from '@mui/icons-material/Close';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  textAlign: 'center'
};
const VideoPlayer = ({ open, setOpen }) => {
  const handleClose = () => setOpen(false);

  return (
    <div style={{ position: 'relative' }}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        disableEnforceFocus
        disableAutoFocus
      >
        <Box sx={style}>
          <CloseIcon sx={{ position: 'absolute', right: 0, top: '-30px', color: '#fff' }} onClick={handleClose} />
          <video autoPlay muted width={'90%'} controls poster={showReelPoster}>
            <source src={heroBannerShowReel} type='video/mp4' />
          </video>
        </Box>
      </Modal>
    </div>
  );
};

export default VideoPlayer;
