import { Box, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';

const About = () => {
  return (
    <>
      <Helmet>
        <meta name='title' content='About Our Studio - MUDROST: PHOTO AND VIDEOGRPAHY' />
        <meta
          name='description'
          content='Meet MUDROST, where we bring your visual ideas to life. Our duo, Vivek and Noor, specialize in food, beverage, and product photography.'
        />
        <meta name='keywords' content='Photo and Videography ' />
      </Helmet>
      <Grid container sx={{ p: { xs: '30px 30px 0 30px', sm: '30px 30px 0 30px', md: '40px 40px 0 40px' } }} rowSpacing={10} columnSpacing={2}>
        <Grid item md={12} sm={12} xs={12}>
          <Box>
            <Card raised={false} sx={{ boxShadow: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <CardMedia
                component={'video'}
                image={
                  'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/about%2Fabout%20page%20banner%20mp4%20comp.mp4?alt=media&token=fe652218-3407-4a63-8ca8-4d13f4a51069'
                }
                alt={'Best Food photographer Banglore'}
                autoPlay
                loop
                muted={true}
                sx={{ width: { sm: '100%', md: '60%' } }}
              />
              <CardContent sx={{ width: { sm: '100%', md: '60%' } }}>
                <Typography variant='body1' sx={{ fontSize: '20px' }}>
                  At <b>mudrost</b>, we mould your tales into reality, ensuring every component reflects the essence of your brand. As a creative crew,
                  specializing in food, beverage, and product photography. We deliver end-to-end production solutions customised to your requirements.
                  <br />
                  <br />
                  Let’s mould your ideas, visions, and dreams together!
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <Box>
            <Card raised={false} sx={{ boxShadow: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <CardMedia
                component={'img'}
                image={
                  'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/about%2Fvivek_about_page.png?alt=media&token=f6fc88d3-eb7f-4cea-86af-a4ace8dd172e'
                }
                alt={'Food Photographer near me'}
                autoPlay
                muted={true}
                sx={{ width: { sm: '100%', md: '70%' } }}
              />
              <CardContent sx={{ width: { sm: '100%', md: '100%' } }}>
                <div style={{ margin: '0 auto', width: '73%' }}>
                  <Typography variant='body1' sx={{ textAlign: 'justify' }}>
                    Vivek has transitioned from an engineer to a dedicated cinematographer with nearly a decade of experience working on various sets. With a
                    keen eye for the intricate dance between light and shadows, his work is deeply inspired by the shapes, textures, and patterns that surround
                    us.
                    <br />
                    Vivek’s love for travel and his curiosity about new places, people, cultures, and languages continuously fuel his creative endeavours.
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Box>
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <Box>
            <Card raised={false} sx={{ boxShadow: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <CardMedia
                component={'img'}
                image={
                  'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/about%2Fnoor_about_page.png?alt=media&token=cddec7ee-40b4-4d6a-8936-8c5594146921'
                }
                alt={'Food Photographer near me'}
                autoPlay
                muted={true}
                sx={{ width: { sm: '100%', md: '70%' } }}
              />
              <CardContent sx={{ width: { sm: '100%', md: '100%' } }}>
                <div style={{ margin: '0 auto', width: '73%' }}>
                  <Typography variant='body1' sx={{ textAlign: 'justify' }}>
                    Noor began her storytelling journey as a young teen, writing short fiction and poetry. She rediscovered her purpose through visual
                    narratives and portrait photography. With a deep love for art, particularly surrealism, she skillfully merges these elements using video
                    editing and photo compositing.
                    <br />
                    Noor’s work revolves around self-expression and retelling narratives. She brings a unique eye for detail and a focus on delicate silhouettes
                    to her compositions
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Box>
        </Grid>
        <Grid item md={12} sm={12} xs={12} sx={{ marginTop: { xs: '-50px !important', sm: '-50px !important' } }}>
          <Box>
            <Card raised={false} sx={{ boxShadow: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <CardMedia
                component={'img'}
                image={
                  'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/about%2Fabout_bts.png?alt=media&token=2532c6db-98e9-4fa3-ad6d-2905ae70b582'
                }
                alt={'Behind-the-scenes of a professional photoshoot'}
                autoPlay
                muted={true}
                sx={{ width: { sm: '100%', md: '100%' } }}
              />
            </Card>
          </Box>
        </Grid>
        {/* <Grid item md={12} xs={12} sm={12} sx={{ textAlign: 'center' }}>
        <Typography variant='h2' sx={{ fontSize: '100px', fontFamily: 'gilroy-bold' }}>
          bild with us
        </Typography>
      </Grid> */}
      </Grid>
    </>
  );
};

export default About;
