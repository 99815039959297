export const landingData = [
  {
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/landing%2Finditrunk_diwali_video.mp4?alt=media&token=4270a6a5-1ad2-4308-8ff1-976fba0c673e',
    thumbnailAltTxt: 'Video production studio in Banglore ',
    caption: 'dreaming desi',
    brand: 'Inditrunk',
    isVideo: true,
    projectId: 12,
    forMobile:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/landing%2Finditrunk_diwali_square_video.mp4?alt=media&token=a280516c-b437-4210-abfe-69cc6daa6dc3'
  },
  {
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/landing%2Falmond_pista_grid.jpg?alt=media&token=680e8e23-b214-43c2-a492-717b64f30e36',
    thumbnailAltTxt: 'Indian Sweet photography : Anand Mullu Muruku',
    caption: 'almond pista',
    brand: 'Anand',
    projectId: 22,
    forMobile:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/landing%2Fanand_square.jpg?alt=media&token=7cc8f8c6-9d56-4f1a-bb71-a2072fdeff6b'
  },
  {
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/landing%2Froma_square.jpg?alt=media&token=dd35a6ff-6115-4ea8-b73f-5830b8fa04ef',
    thumbnailAltTxt: 'Elegant presentation of Roma in various flavors with garnishes',
    caption: 'taste of italy',
    brand: 'Roma',
    projectId: 4
  },
  {
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/landing%2Fanand_muruku_h.jpg?alt=media&token=eab15692-1b84-4655-8c3b-565ff95e07fd',
    thumbnailAltTxt: 'premium Indian sweets photography',
    caption: 'mullu murukku',
    brand: 'Anand',
    projectId: 21,
    forMobile:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/landing%2Fanand_muruku_square.jpg?alt=media&token=0b4b0780-24af-46b6-818d-1c1cfe5a9671'
  },
  {
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/landing%2Ftitan_square.jpg?alt=media&token=846cb2b1-beb6-4e10-b75e-5d0c3b504e4c',
    thumbnailAltTxt: 'how to photoshoot perfume bottle : Skin',
    caption: 'Skinn',
    brand: 'Titan',
    projectId: 18
  },
  {
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/landing%2Fgir_horizontal.jpg?alt=media&token=fdf74bb0-9722-4dc6-ba52-5dbf6bf138e6',
    thumbnailAltTxt: 'Rivaayat : Hyderabad’s culinary traditions',
    caption: 'great indian rasoi',
    brand: 'Casinova',
    projectId: 3,
    forMobile:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/landing%2Fgir_square.jpg?alt=media&token=e4685fe8-62aa-47cf-9e0f-fd9218d611d9'
  },
  {
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/landing%2Ffortune_lhs_video.mp4?alt=media&token=59d13030-8ec0-4dc5-bf4f-49dd6c3e4c19',
    thumbnailAltTxt: 'Dining table setup for videoshoot',
    caption: 'valentine’s day',
    brand: 'Fortune',
    isVideo: true,
    projectId: 2,
    forMobile:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/landing%2Ffortune_square_video.mp4?alt=media&token=a6eec94c-aa96-4864-8808-628f533b7af0'
  },
  {
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/landing%2Fsukh_horizontal.jpg?alt=media&token=ffcbbb8b-2e61-4e6e-bc33-6ca326cd4c59',
    thumbnailAltTxt: 'premium gift hamper shoot near me ',
    caption: 'joy of gifting',
    brand: 'Sukh',
    projectId: 17,
    forMobile:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/landing%2Fsukh_s.jpg?alt=media&token=fa20da08-9011-4a4d-bfde-3c4d23fa33f8'
  },
  {
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/landing%2Fryka_square.jpg?alt=media&token=8b497de6-25d1-466b-b20b-2df8a6dfd37f',
    thumbnailAltTxt: 'Floral Jewelry Photography Ideas',
    caption: 'garden of dreams',
    brand: 'Ryka',
    projectId: 19
  },
  {
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/landing%2Frivaayat_lhs.jpg?alt=media&token=400d33e4-8768-44ea-86bb-87eabb57e8c8',
    thumbnailAltTxt: 'Rivaayat : Hyderabad’s culinary traditions',
    caption: 'royal recipe',
    brand: 'Rivaayat',
    projectId: 20,
    forMobile:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/landing%2Frivaayat_square.jpg?alt=media&token=4df537cc-d7b0-4639-913a-91ea485c305d'
  },
  {
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/landing%2Fsoju_square.jpg?alt=media&token=9fe3b4ea-db68-4c07-a819-f85414310355',
    thumbnailAltTxt: 'Beverage photoshoot with professional lighting',
    caption: 'soju',
    brand: 'Good Day',
    projectId: 5
  },
  {
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/landing%2Ffitto_horizontal.jpg?alt=media&token=1e31c004-cc36-46dc-ad9f-c18f7cc12eaa',
    thumbnailAltTxt: 'captuing stack of tomatos photography',
    caption: 'tomatina',
    brand: 'Fitto',
    projectId: 1,
    forMobile:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/landing%2Ffitto_square.jpg?alt=media&token=edff45e1-8a6c-4a9a-bccb-9480e8bc769e'
  },
  {
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/landing%2Finditrunk_coffee_lhs_video.mp4?alt=media&token=12fa5b37-0062-443c-a0c9-c9583a5943c0',
    thumbnailAltTxt: 'Brass Coffee Maker Photoshoot',
    caption: 'dreaming desi',
    brand: 'Inditrunk',
    isVideo: true,
    projectId: 11,
    forMobile:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/landing%2Finditrunk_coffee_sqaure_video.mp4?alt=media&token=07101a89-30fe-4958-abce-3f8375a8fa78'
  },
  {
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/landing%2Fmama_horizontal.jpg?alt=media&token=c9fb6e90-0037-42c4-9cd0-e4f65b2f74d4',
    thumbnailAltTxt: 'Professional photoshoot ideas for skincare : Mamaearth',
    caption: 'white musk',
    brand: 'Mamaearth',
    projectId: 13,
    forMobile:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/landing%2Fmama_square.jpg?alt=media&token=dac5c077-696a-4f9c-8458-48fce3a8a917'
  },
  {
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/landing%2Fminimalist_square.jpg?alt=media&token=929fc1e4-11b9-489b-83cc-184b8189c278',
    thumbnailAltTxt: 'Professional photoshoot ideas for skincare : Mamaearth',
    caption: 'hide nothing',
    brand: 'Minimalist',
    projectId: 14
  },
  {
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/landing%2Ftilt_h.jpg?alt=media&token=26d320ff-4c38-4a85-aafa-ea618c37154d',
    thumbnailAltTxt: 'Beverage photoshoot with professional lighting',
    caption: 'bubbly rosé',
    brand: 'Tilt',
    projectId: 6,
    forMobile:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/landing%2Ftilt_square.jpg?alt=media&token=e639987a-cdf6-400e-8e00-bb5cb63fa31d'
  },
  {
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/landing%2Fplum_square.jpg?alt=media&token=30568de8-2fe7-40ec-8a47-12ea2be5214b',
    thumbnailAltTxt: 'High End photography for online store',
    caption: 'skin essentials',
    brand: 'Plum',
    projectId: 16
  },
  {
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/landing%2Fatomz_horizontal.jpg?alt=media&token=03e1be74-d471-4580-82d9-93e29e96caf6',
    thumbnailAltTxt: 'Premium shoot for electronic devices',
    caption: 'elektron W7',
    brand: 'Atomz Power',
    projectId: 9,
    forMobile:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/landing%2Fatomz_square.jpg?alt=media&token=e21645aa-a6a8-402e-adac-abff42933a37'
  }
];
