import { Button, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import styles from './Timeline.module.scss';
import { timelineData } from '../../siteData/TimelineData';
import { arrow } from '../../Images';
import { Link } from 'react-router-dom';
export default function TimeLineMobile() {
  return (
    <>
      <Grid container sx={{ p: { xs: '30px 30px 0 30px', sm: '30px 30px 0 30px', md: '40px 40px 0 40px' } }}>
        <Grid item sm={12} xs={12}>
          <Typography variant='h2' sx={{ pb: '10px' }}>
            process
          </Typography>
          <Typography variant='body1'>A breakdown of our project timeline</Typography>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{ p: { xs: '30px 30px 0 30px', sm: '30px 30px 0 30px', md: '40px 40px 0 40px' }, flexDirection: 'column', alignItems: 'center' }}
        rowSpacing={1}
        // columnSpacing={2}
      >
        {timelineData.map(({ title, subSteps }, sIndex) => (
          <>
            <Grid item xs={12} sm={12}>
              <Typography
                variant='h3'
                className={sIndex === 0 ? styles['start-project'] : styles['timeline-heading']}
                sx={{ width: '100%', textAlign: 'center' }}
              >
                {title}
              </Typography>
            </Grid>
            {subSteps?.map(({ title, desc, media }, suIndex) => (
              <Grid item xs={12} sm={12}>
                <Card sx={{ boxShadow: 0 }}>
                  <CardMedia sx={{ width: '30%', m: '0 auto' }} component={'img'} image={media} title='green iguana' />
                  <CardContent sx={{ pt: 0, textAlign: 'center' }}>
                    <Typography variant='h5' component='span' sx={{ color: '#56554D' }}>
                      {title}
                    </Typography>
                    <Typography sx={{ textAlign: 'left', color: '#000' }}>{desc}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
            {sIndex === timelineData.length - 1 && (
              <Grid item xs={12} sm={12} sx={{ mt: 6, mb: 2 }}>
                <Typography sx={{ textAlign: 'left', color: '#000', pl: '16px' }}>Together, let's mould something truly remarkable.</Typography>
              </Grid>
            )}
            {sIndex < timelineData.length - 1 && (
              <Grid item xs={12} sm={12} sx={{ textAlign: 'center' }}>
                <img src={arrow} width='6%' alt='arrow' />
              </Grid>
            )}
          </>
        ))}
        <Link to='/faq' style={{ textDecoration: 'none', color: '#000' }}>
          {/* <Typography variant='h3' className={styles['timeline-heading']}>
                know more
              </Typography> */}
          <Button
            color='primary'
            variant='contained'
            fullWidth
            type='submit'
            className={styles['timeline-knowmore-btn']}
            sx={{ width: 'max-content', fontSize: '15px' }}
          >
            know more
          </Button>
        </Link>
      </Grid>
    </>
  );
}
