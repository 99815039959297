import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import styles from './Header.module.scss';
import { bildLogo } from '../../Images';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
const drawerWidth = 240;
const navItems = ['food & beverage', 'product', 'services', 'process', 'about', 'blog', 'start a project'];

function Header(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  // Mobile menu
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Divider />
      <List>
        {navItems.map((item, index) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'left' }}>
              <Link
                smooth
                to={`/${item.replace(/ /g, '')}`}
                key={item}
                className={index === navItems.length - 1 ? styles['start-project-mobile'] : styles['nav-link-mobile']}
                // className={styles['nav-link-mobile']}
              >
                <ListItemText primary={item} sx={{ fontSize: '50px !important' }} />
              </Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  function getSelectedStyle() {
    return {
      textDecoration: 'underline',
      textDecorationThickness: '2px',
      textUnderlineOffset: '10px'
    };
  }
  return (
    <Box sx={{ display: 'flex', position: 'relative' }} id='header'>
      <CssBaseline />
      <AppBar component='nav' className={styles['nav-bar']} sx={{ position: 'absolute', padding: '0 20px' }}>
        <Toolbar
          sx={{
            // justifyContent: "end",
            display: 'flex',
            justifyContent: 'space-between',
            mr: { xs: '0' }
          }}
        >
          {/* Desktop menu */}
          <div>
            <Box sx={{ paddingTop: { sm: '15px', xs: '15px' } }}>
              <Link to='/'>
                <img src={bildLogo} alt='bild' width={'50%'} />
              </Link>
            </Box>
          </div>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item, index) => (
              <>
                <NavLink
                  exact
                  smooth
                  to={`/${item.replace(/ /g, '')}`}
                  key={item}
                  className={index === navItems.length - 1 ? styles['start-project'] : styles['nav-link-desktop']}
                  style={({ isActive }) => {
                    if (isActive && index !== navItems.length - 1) {
                      return getSelectedStyle();
                    }
                  }}
                >
                  {item}
                </NavLink>
              </>
            ))}
          </Box>
          <IconButton
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{
              //   mr: 2,
              color: '#000',
              display: {
                sm: 'none',
                justifyContent: 'flex-end',
                xs: 'flex'
              }
            }}
          >
            <MenuIcon fontSize='large' color='#000' />
          </IconButton>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          anchor='right'
          container={container}
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              backgroundColor: '#FFF'
            }
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

export default Header;
