import { useFormik, FormikProvider, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import FocusError from './FocusError';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import styles from './StartProject.module.scss';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../firebase-config'; // Your Firebase config
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { useState } from 'react';
import Popup from '../../components/Popup/Popup';
import { useNavigate } from 'react-router-dom';

const StartProject = () => {
  const navigate = useNavigate();
  const services = ['Product Photography', 'Product Videography', 'Food & Beverage Photography', 'Food & Beverage Videography', 'Advertising Film'];
  const budgets = ['Rs.25,000 to Rs.35,000', 'Rs.35,000 to Rs.50,000', 'Rs.50,000 to Rs.1,00,000', '> Rs.1,00,000'];
  const adChannels = ['Google', 'Instagram', 'LinkedIn', 'Behance', 'Pinterest', 'Other'];
  const [isLoading, setIsLoading] = useState(false);
  const [isApiRes, setIsApiRes] = useState({
    openPopup: false,
    isError: false
  });

  // Regular expression for validating Indian mobile numbers
  const indianMobileNumberRegex = /^[6-9]\d{9}$/;

  const validationSchema = yup.object({
    name: yup.string('Enter your name').required('Name is required'),
    aboutCompany: yup.string('Enter your company name').required('Company name is required'),
    services: yup.array().min(1, 'Please select at least one service'),
    budget: yup.string().required('Please choose the budget'),
    email: yup.string().email(),
    phone: yup
      .string()
      .matches(indianMobileNumberRegex, 'Please enter a valid Indian mobile number')
      .test('at-least-one', 'Choose at least one', function (value) {
        const { email } = this.parent;
        return email || value;
      })
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      aboutCompany: '',
      socialLink: '',
      services: [],
      refProject: '',
      budget: '',
      adChannel: '',
      email: '',
      phone: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      values['created_date'] = new Date();
      await addData(values);
      // resetForm();
      navigate('/');
    }
  });

  const addData = async (values) => {
    try {
      const docRef = await addDoc(collection(db, 'newclients'), {
        ...values
      });
      setIsLoading(false);
      console.log('Document written with ID: ', docRef.id);
      setIsApiRes({
        isError: false,
        openPopup: true
      });
    } catch (e) {
      console.error('Error adding document: ', e);
      setIsApiRes({
        isError: true,
        openPopup: true
      });
    }
  };
  function handleCheckboxSelection(svc) {
    const newServices = formik.values.services.includes(svc) ? formik.values.services.filter((service) => service !== svc) : [...formik.values.services, svc];
    formik.setFieldValue('services', newServices);
  }
  const textFieldStyles = {
    width: { xs: '85% !important', sm: '85% !important', md: '58% !important' },
    '& .MuiOutlinedInput-root': {
      border: '1px solid #fff',
      backgroundColor: '#fff',
      borderRadius: '10px',
      '& fieldset': {
        borderColor: '#fff' // Default border color
      },
      '&:hover fieldset': {
        borderColor: '#fff' // Border color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: '#fff' // Border color when focused
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: '#fff'
      }
    }
  };
  const TextFieldErrorMsg = ({ name }) => {
    return <ErrorMessage name={name}>{(msg) => <div className={styles['error-message']}>{msg}</div>}</ErrorMessage>;
  };
  return (
    <Grid
      container
      sx={{ p: { xs: '30px', sm: '30px', md: '40px', color: '#fff', backgroundColor: '#000' } }}
      alignItems='center'
      justifyContent='center'
      flexDirection='column'
    >
      <Popup response={isApiRes} setIsApiRes={setIsApiRes} />
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <Grid item md={6} sx={{ textAlign: 'center', pb: '40px', ml: { md: '-12%' } }}>
        <Typography variant='h2'>Collaboration inquiry</Typography>
        <Typography variant='h6' sx={{ lineHeight: '25px' }}>
          Tell us about your project, and let's mould your story together
        </Typography>
      </Grid>
      <Grid item md={6}>
        <FormikProvider value={formik}>
          <FocusError />
          <form onSubmit={formik.handleSubmit}>
            <ol className={styles['quest-list']}>
              <li>
                <div>
                  <Typography variant='body1' className={styles['pjt-question']}>
                    What's your name?
                  </Typography>
                  <TextField
                    className={styles['project-text-fld']}
                    fullWidth
                    id='name'
                    name='name'
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    sx={textFieldStyles}
                  />
                  <TextFieldErrorMsg name='name' />
                </div>
              </li>
              <li>
                <div>
                  <Typography variant='body1' className={styles['pjt-question']}>
                    Please tell us a bit about your company or business idea
                  </Typography>
                  <TextField
                    className={styles['project-text-fld']}
                    fullWidth
                    id='aboutCompany'
                    name='aboutCompany'
                    value={formik.values.aboutCompany}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    sx={textFieldStyles}
                  />
                  <TextFieldErrorMsg name='aboutCompany' />
                </div>
              </li>
              <li>
                <div>
                  <Typography variant='body1' className={styles['pjt-question']}>
                    Leave a link to your website or social media handle
                  </Typography>
                  <TextField
                    fullWidth
                    className={styles['project-text-fld']}
                    id='socialLink'
                    name='socialLink'
                    value={formik.values.socialLink}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.socialLink && Boolean(formik.errors.socialLink)}
                    helperText={formik.touched.socialLink && formik.errors.socialLink}
                    sx={textFieldStyles}
                  />
                </div>
              </li>
              <li>
                <div>
                  <Typography variant='body1' className={styles['pjt-question']}>
                    How can we help you?
                  </Typography>
                  <Typography variant='body1' className={styles['pjt-question']}>
                    Feel free to select all services that apply to your needs.
                  </Typography>
                  <FormGroup>
                    {services.map((svc) => (
                      <Box className={styles['pjt-options']} sx={{ width: { md: '60%', sm: '90%', xs: '90%' } }}>
                        <FormControlLabel
                          key={svc}
                          className={styles['pjt-option-label']}
                          sx={{ backgroundColor: formik.values.services.includes(svc) && '#56554D', color: formik.values.services.includes(svc) && '#FFF' }}
                          control={
                            <Checkbox
                              checked={formik.values.services.includes(svc)}
                              onChange={() => handleCheckboxSelection(svc)}
                              name='services'
                              value={svc.toString()} // Ensure value is a string
                              sx={{
                                color: '#fff',
                                padding: '10px',
                                '&.MuiCheckbox-root': {
                                  display: 'none'
                                }
                              }}
                            />
                          }
                          label={svc}
                        />
                        {formik.values.services.includes(svc) && <CheckCircleOutlineIcon sx={{ position: 'absolute', right: '30px' }} />}
                      </Box>
                    ))}
                  </FormGroup>
                  {formik.touched.services && formik.errors.services ? <Typography color='error'>{formik.errors.services}</Typography> : null}
                </div>
              </li>
              <li>
                <div>
                  <Typography variant='body1' className={styles['pjt-question']}>
                    Can you share few reference projects you like?
                  </Typography>
                  <TextField
                    className={styles['project-text-fld']}
                    fullWidth
                    id='refProject'
                    name='refProject'
                    value={formik.values.refProject}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.refProject && Boolean(formik.errors.refProject)}
                    helperText={formik.touched.refProject && formik.errors.refProject}
                    sx={textFieldStyles}
                  />
                </div>
              </li>
              <li>
                <div>
                  <Typography variant='body1' className={styles['pjt-question']}>
                    Tell us about your budget.
                  </Typography>
                  <Typography variant='body1' className={styles['pjt-question']}>
                    We require minimum budget of Rs.25,000 to initiate the project.
                  </Typography>
                  <RadioGroup
                    aria-labelledby='demo-radio-buttons-group-label'
                    name='budget'
                    value={formik.values.budget}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.budget && Boolean(formik.errors.budget)}
                  >
                    {budgets.map((budget) => (
                      <Box className={styles['pjt-options']} sx={{ width: { md: '60%', sm: '90%', xs: '90%' } }}>
                        <FormControlLabel
                          className={styles['pjt-option-label']}
                          sx={{
                            backgroundColor: formik.values.budget === budget.toString() && '#56554D',
                            color: formik.values.budget === budget.toString() && '#FFF'
                          }}
                          control={<Radio className={styles['pjt-radio-btn']} checked={formik.values.budget === budget.toString()} value={budget.toString()} />}
                          label={budget}
                        />
                        {formik.values.budget === budget.toString() && <CheckCircleOutlineIcon sx={{ position: 'absolute', right: '30px' }} />}
                      </Box>
                    ))}
                  </RadioGroup>

                  {formik.touched.budget && formik.errors.budget ? <Typography color='error'>{formik.errors.budget}</Typography> : null}
                </div>
              </li>
              <li>
                <div>
                  <Typography variant='body1' className={styles['pjt-question']}>
                    How did you hear about us?
                  </Typography>
                  <RadioGroup
                    name='adChannel'
                    value={formik.values.adChannel}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.adChannel && Boolean(formik.errors.adChannel)}
                  >
                    {adChannels.map((channel) => (
                      <Box className={styles['pjt-options']} sx={{ width: { md: '60%', sm: '90%', xs: '90%' } }}>
                        <FormControlLabel
                          className={styles['pjt-option-label']}
                          sx={{
                            backgroundColor: formik.values.adChannel === channel.toString() && '#56554D',
                            color: formik.values.adChannel === channel.toString() && '#FFF'
                          }}
                          control={
                            <Radio className={styles['pjt-radio-btn']} checked={formik.values.adChannel === channel.toString()} value={channel.toString()} />
                          }
                          label={channel}
                        />
                        {formik.values.adChannel === channel.toString() && <CheckCircleOutlineIcon sx={{ position: 'absolute', right: '30px' }} />}
                      </Box>
                    ))}
                  </RadioGroup>
                </div>
              </li>
              <li>
                <Typography variant='body1' className={styles['pjt-question']}>
                  How can we contact you?
                </Typography>
                <Typography variant='body1' className={styles['pjt-question']}>
                  Answer atleast one field
                </Typography>
                <div>
                  <div style={{ marginBottom: '20px' }}>
                    <TextField
                      className={styles['project-text-fld']}
                      fullWidth
                      id='email'
                      name='email'
                      placeholder='Email'
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      sx={textFieldStyles}
                    />
                    <TextFieldErrorMsg name='email' />
                  </div>
                  <div>
                    <TextField
                      className={styles['project-text-fld']}
                      fullWidth
                      id='phone'
                      name='phone'
                      placeholder='Mobile'
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      // error={formik.touched.phone && Boolean(formik.errors.phone)}
                      // helperText={formik.touched.phone && formik.errors.phone}
                      inputProps={{
                        inputMode: 'numeric',
                        pattern: '[6-9]{1}[0-9]{9}',
                        maxLength: 10
                      }}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                      }}
                      sx={textFieldStyles}
                    />
                    <TextFieldErrorMsg name='phone' />
                  </div>
                </div>
              </li>
            </ol>

            <Box sx={{ width: { md: '70%', sm: '100%', xs: '100%' }, marginTop: '8%', textAlign: 'center' }}>
              <Button
                color='primary'
                variant='contained'
                fullWidth
                type='submit'
                className={styles['submit-btn']}
                sx={{ width: { md: '40%', sm: '40%', xs: '40%' } }}
              >
                <Typography variant='h6' sx={{ fontSize: '19px', lineHeight: '30px' }}>
                  submit
                </Typography>
              </Button>
            </Box>
          </form>
        </FormikProvider>
      </Grid>
    </Grid>
  );
};
export default StartProject;
