import { Card, CardMedia, Grid, Typography } from '@mui/material';
import { allBlogs } from '../../siteData/Blogs';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Blogs = () => {
  const navigate = useNavigate();
  const handleCardClick = (blog) => {
    navigate(`/blog/${blog.id}`);
  };
  return (
    <>
      <Helmet>
        <meta name='title' content='BLOGS by MUDROST STUDIOS ; Photography & Videography' />
        <meta name='description' content='MUDROST - Keeps you updated about tips, tricks, hacks of Photography. Explore our blog Section Now!' />
        <meta name='keywords' content='PRODUCT PHOTOGRAPHY' />
      </Helmet>
      <Grid container columnSpacing={4} rowSpacing={4} sx={{ p: { xs: '30px', sm: '30px', md: '40px' } }}>
        {allBlogs.map(({ thumbnail, thumbnailAltText, isFeatured, banner, title }, bIndex) => (
          <>
            {isFeatured ? (
              <Grid item md={12} sm={12}>
                <Card raised={false} sx={{ borderRadius: '20px', cursor: 'pointer' }} onClick={() => handleCardClick(allBlogs[bIndex])}>
                  <CardMedia component='img' image={banner} alt={thumbnailAltText} sx={{ height: { md: '600px', xs: 'auto', sm: 'auto' } }} />
                </Card>
                <Typography
                  variant='h3'
                  sx={{
                    p: '20px 10px',
                    fontSize: { md: '28px', sm: '16px', xs: '16px' },
                    fontWeight: { sm: 'bold', xs: 'bold', md: 'auto' },
                    lineHeight: '20px !important'
                  }}
                >
                  {title}
                </Typography>
              </Grid>
            ) : (
              <Grid item md={4} sm={12} xs={12}>
                <Card raised={false} sx={{ borderRadius: '20px', cursor: 'pointer' }} onClick={() => handleCardClick(allBlogs[bIndex])}>
                  <CardMedia component='img' image={thumbnail} alt={thumbnailAltText} />
                </Card>
                <Typography variant='h6' sx={{ p: '10px', lineHeight: '20px  !important' }}>
                  {title}
                </Typography>
              </Grid>
            )}
          </>
        ))}
      </Grid>
    </>
  );
};

export default Blogs;
