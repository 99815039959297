import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './globalComponents/Header/Header';
import Footer from './globalComponents/Footer/Footer';
import Home from './pages/Home/Home';
import HeroBanner from './components/HeroBanner/HeroBanner';
import FoodAndBeverages from './pages/FoodAndBeverages/FoodAndBeverages';
import Products from './pages/Products/Products';
import Process from './pages/Process/Process';
import Services from './pages/Services/Services';
import About from './pages/About/About';
import StartProject from './pages/StartProject/StartProject';
import ProjectDetail from './pages/ProjectDetail/ProjectDetail';
import Faq from './pages/Faq/Faq';
import Blogs from './pages/Blogs/Blogs';
import BlogDetails from './pages/Blogs/BlogDetails';
import Contact from './pages/Contact/Contact';
import PageNotFound from './pages/PageNotFound';

const theme = createTheme({
  // Override or create new styles, colors, palettes...
  palette: {
    // primary: {
    //   // main: "#19398a",
    // },
    icons: {
      main: '#FFF'
    },
    textColor: {
      main: '#FFF'
    }
  },
  typography: {
    h1: {
      fontSize: '64px',
      lineHeight: '64px',
      fontFamily: 'gilroy-bold',
      '@media (max-width:600px)': {
        // for mobile screens
        fontSize: '36px',
        lineHeight: '36px'
      }
    },
    h2: {
      fontSize: '44px',
      lineHeight: '64px',
      fontWeight: 'bold',
      fontFamily: 'notosans-regular',
      '@media (max-width:600px)': {
        // for mobile screens
        fontSize: '26px',
        lineHeight: '36px'
      }
    },
    h3: {
      fontSize: '24px',
      lineHeight: '64px',
      fontFamily: 'notosans-regular',
      '@media (max-width:600px)': {
        // for mobile screens
        fontSize: '20px',
        lineHeight: '36px'
      }
    },
    h6: {
      fontSize: '15px',
      fontWeight: 'bold',
      lineHeight: '40px',
      fontFamily: 'notosans-regular',
      '@media (max-width:600px)': {
        // for mobile screens
        fontSize: '16px',
        lineHeight: '36px'
      }
    },
    body1: {
      fontSize: '16px',
      fontFamily: 'notosans-regular',
      // lineHeight: "64px",
      '@media (max-width:600px)': {
        // for mobile screens
        fontSize: '14px',
        lineHeight: '26px'
      }
    },
    subtitle1: {
      fontFamily: 'notosans-regular',
      fontSize: '22px',
      // lineHeight: "64px",
      fontWeight: '600',
      '@media (max-width:600px)': {
        // for mobile screens
        fontSize: '20px'
      }
    },
    subtitle2: {
      fontFamily: 'notosans-regular',
      fontSize: '14px',
      // lineHeight: "64px",
      fontWeight: '400',
      '@media (max-width:600px)': {
        // for mobile screens
        fontSize: '14px'
      }
    }
  }
});
function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <HeroBanner />
        <header>
          <Header />
        </header>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/food&beverage' element={<FoodAndBeverages />} />
          <Route path='/product' element={<Products />} />
          <Route path='/blog' element={<Blogs />} />
          <Route path='/services' element={<Services />} />
          <Route path='/process' element={<Process />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/faq' element={<Faq />} />
          <Route path='/startaproject' element={<StartProject />} />
          <Route path='/projectdetails/:id' element={<ProjectDetail />} />
          <Route path='/blog/:id' element={<BlogDetails />} />
          <Route path='*' element={<PageNotFound />} />
        </Routes>
        <footer>
          <Footer />
        </footer>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
