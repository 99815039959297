import { Helmet } from 'react-helmet';
import ProjectsGrid from '../../components/ProjectsGrid/ProjectsGrid';
import { projects } from '../../siteData/Projects';

const Products = () => {
  return (
    <>
      <Helmet>
        <meta name='title' content="Bangalore's Leading Food and Product Photography Studio" />
        <meta
          name='description'
          content="Looking for Bangalore's Leading Food and Product Photography Studio for your business? then You are at the right place. MUDROST Studio is a Bangalore-based product photographer who works with top brands. Visit our website now!"
        />
        <meta name='keywords' content='Product Photography in Bangalore' />
      </Helmet>
      <ProjectsGrid projects={projects} category={'products'} />
    </>
  );
};

export default Products;
