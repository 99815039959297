import { useEffect } from 'react';
import { useFormikContext } from 'formik';

const FocusError = () => {
  const { isSubmitting, isValidating, errors, touched } = useFormikContext();

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      const firstErrorField = Object.keys(errors).find((key) => errors[key] && touched[key]);
      if (firstErrorField) {
        const errorElement = document.querySelector(`[name="${firstErrorField}"]`);
        if (errorElement) {
          errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
          errorElement.focus({ preventScroll: true });
        }
      }
    }
  }, [isSubmitting, isValidating, errors, touched]);

  return null;
};

export default FocusError;
