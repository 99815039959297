export const services = [
  {
    name: 'product photography',
    price: 'starting at Rs.30,000 ',
    description:
      'We specialize in crafting visually appealing content tailored to your unique branding requirements. Capture the essence of your product with our comprehensive photography packages.',
    bulletHeading: 'We offer:',
    bullets: ['E-commerce photography', 'Stylized product photography', 'Advertising campaigns'],
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/services%2Fservice_product_photography.jpg?alt=media&token=e59fa3e6-aa3d-40cc-a03c-fc6f68067546',
    thumbnailAltText: 'Gift Hampher photoshoot near me '
  },
  {
    name: 'product videography',
    price: 'starting at Rs.50,000',
    description:
      'We create captivating product videos moulded to your needs, aligning with industry standards and visual storytelling trends. Capture the essence of your product with our comprehensive videography packages',
    bulletHeading: 'We offer:',
    bullets: ['Product demo video', 'Advertising campaigns'],
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/services%2Fservice_product_videography.gif?alt=media&token=f2c61187-48cc-4eab-83c9-92087a2645fc',
    thumbnailAltText: 'Product Photoshoot : IOT EV Charger'
  },
  {
    name: 'food and beverage photography',
    price: 'starting at Rs.25,000',
    description:
      'Discover the perfect solution for all your food and beverage photography needs. Whether you prefer creative, unique shoots or classic, minimalist styles, our comprehensive photography packages have you covered.',
    bulletHeading: 'We offer:',
    bullets: ['Restaurant menu shoot', 'E-commerce food and beverage shoot', 'Advertising and digital campaign shoots'],
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/services%2Fservice_fnb_photography.jpg?alt=media&token=f12143fa-86fd-4fc0-b803-b7fd3acd6a88',
    thumbnailAltText: 'Best Food photographer Banglore'
  },
  {
    name: 'food and beverage videography',
    price: 'starting at Rs.35,000',
    description:
      "Whether you're a home cook, a chef, a restaurant, or a brand, we've got you covered. Our videography packages add a professional touch, capturing the essence of what makes you unique.",
    bulletHeading: 'We offer:',
    bullets: ['Reel video shoot', 'Recipe video shoot (stylized)', 'Recipe video shoot (Regular)'],
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/services%2Fservice_fnb_videography.gif?alt=media&token=14884210-4716-4057-b2b1-f6e316908d86',
    thumbnailAltText: 'Video production in Banglore '
  }
];
