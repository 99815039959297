import { Card, CardMedia, Grid, Skeleton, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ProjectsGrid = ({ projects, category, excludeId = null, isRelated = false }) => {
  const navigate = useNavigate();
  const gridItems = projects.filter((p) => p.category === category && p.id !== excludeId);
  const handleCardClick = (project) => {
    navigate(`/projectdetails/${project.id}`);
  };
  const [loadingState, setLoadingState] = useState(Array(gridItems.length).fill(true));

  // Handle image load event
  const handleImageLoad = (index) => {
    const newLoadingState = [...loadingState];
    newLoadingState[index] = false; // Mark this image as loaded
    // setLoadingState(newLoadingState);
    setLoadingState((prevState) => {
      const newLoadingState = [...prevState];
      newLoadingState[index] = false; // Mark this image as loaded
      return newLoadingState;
    });
  };

  return (
    <Grid container columnSpacing={4} rowSpacing={4} sx={{ p: { xs: '30px', sm: '30px', md: '40px' } }}>
      {isRelated && (
        <Grid item md={12} xs={12}>
          <Typography variant='h5' sx={{ paddingBottom: '0', fontWeight: 'bold' }}>
            related projects
          </Typography>
        </Grid>
      )}
      {gridItems.map(({ thumbnail, thumbnailAltText }, pIndex) => (
        <Grid item md={4} sm={12} xs={12} key={pIndex}>
          <Card raised={false} sx={{ borderRadius: '20px', cursor: 'pointer' }} onClick={() => handleCardClick(gridItems[pIndex])}>
            {/* Show Skeleton while the image is loading */}
            {loadingState[pIndex] && <Skeleton variant='rectangular' animation='wave' width='100%' height={'356px'} />}
            <CardMedia component='img' image={thumbnail} alt={thumbnailAltText} onLoad={() => handleImageLoad(pIndex)} />
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default ProjectsGrid;
