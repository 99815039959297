import { Box, Button, Card, CardMedia, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { landingData } from '../../siteData/LandingPage';
import { useEffect, useState } from 'react';

const Home = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isShowAll, setIsShowAll] = useState(false);
  const findGridSize = (pindex) => {
    return pindex % 3 === 0 ? 12 : pindex % 2 === 0 ? 4.5 : 7.5;
  };
  const handleCardClick = (projectId) => {
    navigate(`/projectdetails/${projectId}`);
  };
  let projects = [...landingData];
  if (!isShowAll && mobile) {
    projects = landingData.slice(0, 8);
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <>
      <Grid container columnSpacing={4} rowSpacing={4} sx={{ p: { xs: '30px', sm: '30px', md: '40px' } }}>
        {projects.map(({ isVideo, thumbnail, thumbnailAltTxt, caption, brand, projectId, forMobile }, pindex) => (
          <Grid item md={findGridSize(pindex)} onClick={() => handleCardClick(projectId)} sx={{ cursor: 'pointer' }}>
            <Card raised={false} sx={{ borderRadius: '20px', boxShadow: 0, position: 'relative' }}>
              <CardMedia
                component={isVideo ? 'video' : 'img'}
                image={mobile && forMobile ? forMobile : thumbnail}
                alt={thumbnailAltTxt}
                autoPlay
                muted={true}
                loop
              />
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '0',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  background: 'linear-gradient(to top, rgb(0 0 0 / 55%), rgb(0 0 0 / 21%) 70%, rgb(0 0 0 / 0%) 100%)',
                  color: '#fff',
                  paddingTop: '25px',
                  '& h3': {
                    lineHeight: '20px'
                  }
                }}
              >
                <Typography variant='h3' sx={{ fontSize: '30px', fontFamily: 'gilroy-light' }}>
                  {caption}
                </Typography>
                <Typography variant='h6' sx={{ fontWeight: 'bold', fontFamily: 'gilroy-bold' }}>
                  {brand}
                </Typography>
              </Box>
            </Card>
          </Grid>
        ))}
        {!isShowAll && (
          <Grid item sm={12} xs={12} sx={{ textAlign: 'center', display: { md: 'none' } }}>
            <Button
              color='primary'
              variant='contained'
              fullWidth
              type='submit'
              onClick={() => setIsShowAll(true)}
              sx={{ width: 'max-content', fontSize: '15px', backgroundColor: '#000', borderRadius: '20px', textTransform: 'none' }}
            >
              show all
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Home;
