export const faq = [
  {
    question: 'Why choose mudrost?',
    answer:
      'We’re a one-stop solution for all your production needs related to food, beverages, and products. Have an idea? Need us to develop one for you? Or want to collaborate? We’re here for it all.'
  },
  {
    question: 'Where are you located?',
    answer: 'We are based in Bangalore, Karnataka.'
  },
  {
    question: 'Do you offer location shoots outside of your studio?',
    answer: 'Absolutely! We’ll go wherever the story takes us.'
  },
  {
    question: 'How do I book your services?',
    answer:
      " Simply fill out the <a href='/startaproject' style='color:#000;'>Start a Project</a> form, and we’ll schedule an exploration call to discuss your vision."
  },
  {
    question: 'How much do you charge for a shoot?',
    answer:
      "Our rates vary based on your specific service requirements. Please do  <a href='/contact' style='color:#000;'>contact us</a> with your project details and we will get back to you with a customized budget."
  },
  {
    question: 'Can I customize my shoot requirements apart from your packages?',
    answer: 'Yes! We can tailor our shoots to meet your unique needs.'
  },
  {
    question: 'What is the average timeline for a project?',
    answer:
      'Timelines depend on your brief. For a one-day production, we typically spend a week preparing after your initial inquiry, followed by an 8-hour shoot day. Post-production, including revisions, usually takes about another week, ensuring everything is perfect before final delivery.'
  }
];
