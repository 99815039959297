export const projects = [
  {
    id: 1,
    category: 'foodandbeverages',
    title: 'Fitto',
    banner: {
      isVideo: false,
      bannerSrc:
        'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Ffitto%2Ffnb_fiito_cover.jpg?alt=media&token=6a4ed37a-ad23-4962-b02f-3394e94e7b11',
      bannerAltTxt: 'captuing stack of tomatos photography'
    },
    media: [
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Ffitto%2Ffnb_fiito_1.jpg?alt=media&token=b043e23b-9bc5-44cc-a8b0-3a8b6f586f19',
        mediaAltTxt: 'captuing stack of tomatos photography'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Ffitto%2Ffnb_fiito_2.jpg?alt=media&token=fb41eb02-e889-4c76-b372-ddd3f5be75f0',
        mediaAltTxt: 'captuing stack of tomatos photography'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Ffitto%2Ffnb_fiito_3.jpg?alt=media&token=0ff6a253-3005-481c-a840-193c280ca1c6',
        mediaAltTxt: 'captuing stack of tomatos photography'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Ffitto%2Ffnb_fiito_4.jpg?alt=media&token=5268f98f-e54c-4c9e-8751-f922c9d7e3a3',
        mediaAltTxt: 'Capturing best behind-the-scenes of a professional photoshoot'
      }
    ],
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Ffitto%2Ffitto_seeds_thumbnail.webp?alt=media&token=e790e0f6-d895-493e-b103-38cb4d3f58b0',
    thumbnailAltText: 'captuing stack of tomatos photography',
    meta: {
      brand: 'Fitto Seeds',
      'photography & lighting': 'vivek s k',
      'post production': 'vivek s k',
      description:
        'Fitto is all about freshness and our aim throughout the shoot was to evoke that. With a basket of fresh tomatoes at hand, we had to set the stage and sizzle things up. With just a spray bottle and one keylight, we ended up with shots that highlighted the hues of the product.'
    }
  },
  {
    id: 2,
    category: 'foodandbeverages',
    title: 'Fortune',
    banner: {
      isVideo: false,
      bannerSrc:
        'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Ffortune%2Ffnb_fortune_cover.jpg?alt=media&token=94eb2ea7-f61d-4312-8b15-03665e40ca18',
      bannerAltTxt: 'Dining table setup for photoshoot'
    },
    media: [
      {
        isVideo: true,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Ffortune%2Ffnb_fortune_film_1.mp4?alt=media&token=1997b79c-b795-4235-8df6-a2da530171da',
        mediaAltTxt: 'Fortune: Capturing Random Moments '
      },
      {
        isVideo: true,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Ffortune%2Ffnb_fortune_film_2.mp4?alt=media&token=10329248-5d6c-45fd-a195-86682494d5ac',
        mediaAltTxt: 'Bowl of rich matar paneer curry with a side of freshly baked roti'
      },
      {
        isVideo: true,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Ffortune%2Ffnb_fortune_film_3.mp4?alt=media&token=98df1f6c-45e5-4716-a5bf-7c037c71518a',
        mediaAltTxt: 'Fortune: Capturing Random Moments '
      }
    ],
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Ffortune%2Ffortune_film1_thumbnail.webp?alt=media&token=a5e9be6e-3282-45c6-b38a-3e72f87fb10f',
    thumbnailAltText: 'Dining table setup for photoshoot',
    meta: {
      brand: 'fortune foods',
      agency: 'social beat',
      'production house': 'mudrost studio',
      director: 'vivek s k',
      'creative producer': 'noorhanaz khatoon',
      'executive producer': 'naveen tejaswi',
      dop: 'darshan swaroop',
      'editor and vfx': 'noorhanaz khatoon',
      colourist: 'rakesh hosaamani',
      'food stylish and art director': 'kanishka prabhu',
      mua: 'shivanya and vidya',
      cast: 'aishwarya  shankar, abhi das, chakravarthy reddy, swetha sanketh, harish c k',
      description:
        "Love finds its way to the soul through food, and to show this precious bond of love, we  teamed up with Social Beat to bring the Fortune Valentine's Day campaign to life. It was equally exciting yet challenging to tell three beautiful stories that celebrate ‘ghar ka khana’ as the love language."
    },
    clientQuote: {
      clientName: 'Soumyanshu Behera',
      designation: 'Lead - Video & Creative Strategy, Social Beat',
      quote:
        "It's difficult to find a partner who sees a campaign through irrespective of the challenge in hand, we were glad to choose mudrost to help us with our very last minute Valentine's Day campaign which was executed thoroughly and the team ensured to make it look far from last minute. We look forward to working with them in the future, and absolutely appreciate the efforts and the ownership of the project."
    }
  },
  {
    id: 3,
    category: 'foodandbeverages',
    title: 'Casinova',
    banner: {
      isVideo: false,
      bannerSrc:
        'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fgir%2Ffnb_gir_cover.jpg?alt=media&token=93763f68-2a73-4452-b4d2-89a51cce56d7',
      bannerAltTxt: 'Ideas to place Indian spices and herbs in the background'
    },
    media: [
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fgir%2Ffnb_gir_1.jpg?alt=media&token=0d7fe0a3-a0a6-456b-891e-54ad623724ce',
        mediaAltTxt: 'Ideas to place Indian spices and herbs in the background'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fgir%2Ffnb_gir_2.jpg?alt=media&token=df7e1a5a-bc54-40fc-b848-6eace41ea96b',
        mediaAltTxt: 'Dining table setup for photoshoot'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fgir%2Ffnb_gir_3.jpg?alt=media&token=4327da53-77dc-4a62-9106-8fced1ada4b1',
        mediaAltTxt: 'Ideas to place Indian spices and herbs in the background'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fgir%2Ffnb_gir_4.jpg?alt=media&token=0d192c27-30cb-4e67-8f4d-08f4599c6048',
        mediaAltTxt: 'Ideas to place Indian spices and herbs in the background'
      }
    ],
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fgir%2Fgir_thumbnail.webp?alt=media&token=973295ef-82a9-4a68-bfff-05c429440151',
    thumbnailAltText: 'Dining table setup for photoshoot',
    meta: {
      brand: 'Casinova',
      'photography & lighting': 'vivek s k',
      'post production': 'vivek s k',
      description:
        'Casinova is all about bold, vibrant flavours. To bring that essence to life in the photographs, we infused our set design with the spices and ingredients that make their dishes special. It’s like capturing a culinary symphony where every shot celebrates both the food and its flavorful story.'
    }
  },
  {
    id: 4,
    category: 'foodandbeverages',
    title: 'Roma',
    banner: {
      isVideo: false,
      bannerSrc:
        'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Froma%2Ffnb_roma_cover.jpg?alt=media&token=9604263a-3869-4a0a-a743-e357272aa524',
      bannerAltTxt: 'Elegant presentation of Roma in various flavors with garnishes'
    },
    media: [
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Froma%2Ffnb_roma_1.jpg?alt=media&token=93c5e790-a933-4762-a971-95da4126497b',
        mediaAltTxt: 'Elegant presentation of Roma in various flavors with garnishes'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Froma%2Ffnb_roma_2.jpg?alt=media&token=0e7f04f3-833c-44eb-914e-d133374d59e2',
        mediaAltTxt: 'Elegant presentation of Roma in various flavors with garnishes'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Froma%2Ffnb_roma_3.jpg?alt=media&token=b0d73db6-2848-49b4-ba4a-13eab2fb8f46',
        mediaAltTxt: 'Elegant presentation of Roma in various flavors with garnishes'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Froma%2Ffnb_roma_4.jpg?alt=media&token=d3b65011-02ad-4326-8d7f-d0633e0b1029',
        mediaAltTxt: 'Elegant presentation of Roma in various flavors with garnishes'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Froma%2Ffnb_roma_5.jpg?alt=media&token=6c9e5d4f-396d-4fe6-8b7b-65823fb9482f',
        mediaAltTxt: 'Elegant presentation of Roma in various flavors with garnishes'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Froma%2Ffnb_roma_6.jpg?alt=media&token=cdf677c6-acf5-475f-9b2a-410cdb966a07',
        mediaAltTxt: 'How to capture best behind-the-scenes of a professional photoshoot'
      }
    ],
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Froma%2Froma_thumbnail.webp?alt=media&token=8b157833-bce4-4db0-816e-98d7924f7cb5',
    thumbnailAltText: 'Elegant presentation of Roma in various flavors with garnishes',
    meta: {
      brand: 'Roma',
      'photography & lighting': 'noorhanaz khatoon',
      'post production': 'noorhanaz khatoon',
      description:
        'Roma serves you the taste of Italy on a platter. To showcase a brand that celebrates authenticity in every bite, we wanted to keep the images as real as possible. The magic of  natural light translated the soul of Roma into the frames.'
    }
  },
  {
    id: 5,
    category: 'foodandbeverages',
    title: 'Good Day',
    banner: {
      isVideo: false,
      bannerSrc:
        'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fsoju%2Ffnb_soju_cover.jpg?alt=media&token=f0db081c-ff0f-4ab8-867b-aea1300c0ad4',
      bannerAltTxt: 'Bottle of soju with a traditional Korean label'
    },
    media: [
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fsoju%2Ffnb_soju_1.jpg?alt=media&token=5b4a3390-88c1-44b5-ac5a-b41a31abf167',
        mediaAltTxt: 'Bottle of soju with a traditional Korean label'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fsoju%2Ffnb_soju_2.jpg?alt=media&token=e3ec3fd3-51c4-4e15-be86-9248e65f95d7',
        mediaAltTxt: 'Bottle of soju with a traditional Korean label'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fsoju%2Ffnb_soju_3.jpg?alt=media&token=ca4522a5-f193-491e-89e4-769750eed3fa',
        mediaAltTxt: 'Bottle of soju with a traditional Korean label'
      }
    ],
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fsoju%2Fsoju_thumbnail.webp?alt=media&token=1c9b50e4-a977-41f8-9f9f-dfd1697ed8a2',
    thumbnailAltText: 'Bottle of soju with a traditional Korean label',
    meta: {
      brand: 'Good Day',
      'photography & lighting': 'vivek s k',
      'set design': 'noorhanaz khatoon',
      'post production': 'noorhanaz khatoon',
      description:
        'Ever looked at something and felt instantly transported to another world? Soju does that for us, evoking relaxed, quality moments. We aimed to capture a visual escape that invites you to unwind. '
    }
  },
  {
    id: 6,
    category: 'foodandbeverages',
    title: 'TiLT',
    banner: {
      isVideo: false,
      bannerSrc:
        'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Ftilt%2Ffnb_tilt_cover.jpg?alt=media&token=f3439ce6-6244-422a-a420-7a9036cb44b0',
      bannerAltTxt: 'Beverage photoshoot with professional lighting'
    },
    media: [
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Ftilt%2Ffnb_tilt_1.jpg?alt=media&token=61ddd126-8d3a-4733-82ed-dbb9319181b7',
        mediaAltTxt: 'Beverage photoshoot with professional lighting'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Ftilt%2Ffnb_tilt_2.jpg?alt=media&token=985a6402-5b8d-43fc-ae65-78c09d3ceac7',
        mediaAltTxt: 'Beverage photoshoot with professional lighting'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Ftilt%2Ffnb_tilt_3..jpg?alt=media&token=b93232ed-747d-4e1a-83bd-52f020e0ce92',
        mediaAltTxt: 'Beverage photoshoot with professional lighting'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Ftilt%2Ffnb_tilt_4.jpg?alt=media&token=4e0a40ac-3b6c-49e2-86e0-5f7cac223943',
        mediaAltTxt: 'Beverage photoshoot with professional lighting'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Ftilt%2Ffnb_tilt_5.jpg?alt=media&token=22bcd09f-4319-43f6-bbe0-fd3f8d288e74',
        mediaAltTxt: 'Beverage photoshoot with professional lighting'
      }
    ],
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Ftilt%2Ftilt_thumbnail.webp?alt=media&token=92524a81-7a61-4839-832f-a65e5336fcca',
    thumbnailAltText: 'Beverage photoshoot with professional lighting',
    meta: {
      brand: 'TiLT',
      'photography & lighting': 'noorhanaz khatoon & vivek s k',
      'post production': 'noorhanaz khatoon',
      description:
        "We can safely say that this spec shoot was inspired by the product itself. The blush pink tone and funky font set the stage. We ran with a monochromatic theme and built on it, creating a gallery that's fresh, vibrant, and lively."
    }
  },

  {
    id: 9,
    category: 'products',
    title: 'Atomz Power',
    banner: {
      isVideo: false,
      bannerSrc:
        'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fatmoz%2Fpro_atomz_cover.jpg?alt=media&token=7e95370d-147d-48db-8275-4d420523a47c',
      bannerAltTxt: 'Product Photoshoot : IOT EV Charger'
    },
    media: [
      {
        isVideo: true,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fatmoz%2Fpro_atomz_video.mp4?alt=media&token=fdc510bc-4a07-40a6-9ca7-24afaaa13a3c',
        mediaAltTxt: 'Professional video shoot for electric charger'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fatmoz%2Fpro_atomz_1.jpg?alt=media&token=4b728507-8ee6-46c1-b0bb-41efbc135bad',
        mediaAltTxt: 'Premium shoot for electronic devices'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fatmoz%2Fpro_atomz_2.jpg?alt=media&token=c02aa211-e622-4b9e-ac64-8a5abb5f5593',
        mediaAltTxt: 'Premium shoot for electronic devices'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fatmoz%2Fpro_atomz_3.jpg?alt=media&token=c0638b95-38e6-4845-b1d7-ddcf3f5b3c18',
        mediaAltTxt: 'Premium shoot for electronic devices'
      }
    ],
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fatmoz%2Fatomz_thumbnail.webp?alt=media&token=b32a240f-bf83-4113-a32b-377b42059027',
    thumbnailAltText: 'Product Photoshoot : IOT EV Charger',
    meta: {
      brand: 'Atomz Power',
      director: ' thameem ansari',
      cinematographer: 'vivek s k',
      editor: 'vivek s k',
      colourist: 'noorhanaz khatoon',
      description:
        'Atomz brought us their EV charging series product, Elektron, and asked for a fun twist on the demo video. We were all in. To showcase the bold red of the product, we opted for a black background to highlight the product. After capturing the shots, we put our spin on the post-production, creating a dynamic demo for their futuristic product.'
    }
  },
  {
    id: 10,
    category: 'products',
    title: 'Fastrack',
    banner: {
      isVideo: false,
      bannerSrc:
        'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Ffastrack%2Fpro_fastrack_cover.jpg?alt=media&token=29aea17a-563c-41a5-8642-c8a301ed92c8',
      bannerAltTxt: 'Digital watch Photoshoot : Fastrack'
    },
    media: [
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Ffastrack%2Fpro_fastrack_1.jpg?alt=media&token=678424dc-7717-4c88-8751-67e62b8f2e73',
        mediaAltTxt: 'Stylish wristwatch on a black background photography'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Ffastrack%2Fpro_fastrack_2.jpg?alt=media&token=ecda2948-7ddf-49c3-9d11-79ee4ad9a274',
        mediaAltTxt: 'Professional photoshoot ideas for luxury smart watches '
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Ffastrack%2Fpro_fastrack_3.jpg?alt=media&token=2e4de0a5-55d5-415d-b552-c30985d25ede',
        mediaAltTxt: 'Premium quality fastrack watch closeup shot'
      }
    ],
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Ffastrack%2Ffastrack_thumbnail.webp?alt=media&token=b8099a61-9ea7-4a5f-8b06-48fd077b3ddd',
    thumbnailAltText: 'Digital watch Photoshoot : Fastrack',
    meta: {
      brand: 'Fastrack',
      '  photography & lighting': ' vivek s k',
      ' post production': ' vivek s k',
      description:
        'Embracing the spirit of reinvention, Fastrack inspired us to create visuals that are simple yet dramatic. We let the products shine by setting them against a sleek black background, giving them the spotlight they deserve.'
    }
  },
  {
    id: 11,
    category: 'products',
    title: 'Inditrunk - Coffee',
    banner: {
      isVideo: false,
      bannerSrc:
        'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Finditrunk%2Fpro_indcoffee_cover.jpg?alt=media&token=9a02c8cc-d77f-4d6c-aba2-56857b4eaaf8',
      bannerAltTxt: 'High-resolution images of handcrafted brass filter coffee makers'
    },
    media: [
      {
        isVideo: true,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Finditrunk%2Fpro_inditrunk_coffee_video%20.mp4?alt=media&token=7eba82be-b09f-4773-8be6-c1e058772ce9',
        mediaAltTxt: 'Professional Videography for beverages in Bangalore'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Finditrunk%2Fpro_inditrunk_coffee_1.png?alt=media&token=2dfa161e-17fb-4388-b26c-43548609a552',
        mediaAltTxt: 'Brass Coffee Maker Photoshoot'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Finditrunk%2Fpro_indcoffee_2.jpg?alt=media&token=ad0668dd-f564-4f47-a9f2-335499fbe853',
        mediaAltTxt: 'Brass Coffee Maker Photoshoot'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Finditrunk%2Fpro_indcoffee_3.jpg?alt=media&token=bbc74c27-f25c-4616-9dd8-193b754ca8cd',
        mediaAltTxt: 'Brass Coffee Maker Photoshoot'
      }
    ],
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Finditrunk%2Finditrunk_coffee_thumbnail.webp?alt=media&token=75c13961-a8e0-4ef3-9714-308453b406b7',
    thumbnailAltText: 'High-resolution images of handcrafted brass filter coffee makers',
    meta: {
      brand: 'Inditrunk',
      'creative producer': 'vivek s k',
      'writer and director': 'naveen tejaswi',
      cinematographer: 'darshan swaroop',
      art: 'sanskriti bist',
      editor: 'noorhanaz khatoon',
      music: 'vivan radhakrishna',
      colourist: 'siddarth sunil',
      description:
        'For Inditrunk’s “Dreaming Desi” campaign, the aim was to connect with the Indian diaspora craving a touch of home. Our goal was to evoke the cosy memory of a leisurely evening, savouring homemade filter coffee with a good book. With a passionate team and a touch of creativity, we crafted a visual story that wraps you in that comforting, nostalgic embrace.'
    }
  },
  {
    id: 12,
    category: 'products',
    title: 'Inditrunk - Diwali',
    banner: {
      isVideo: false,
      bannerSrc:
        'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Findi%20diwali%2Fpro_diwali_cover.jpg?alt=media&token=5913d6ea-f551-4556-9cfe-5e264e3415ba',
      bannerAltTxt: 'Ethnic Wear photography : Inditrunk'
    },
    media: [
      {
        isVideo: true,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Findi%20diwali%2Fpro_inditrunk_diwali_video%20.mp4?alt=media&token=10c4128d-c107-40d7-b42b-fdf1f7163bf1',
        mediaAltTxt: 'Professional video shoot ideas for clothing brand :  Inditrunk'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Findi%20diwali%2Fpro_diwali_1.jpg?alt=media&token=a57420f4-6c22-4cc0-bd3d-601aed1490ee',
        mediaAltTxt: 'Indian Wear photography : Inditrunk'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Findi%20diwali%2Fpro_diwali_2.jpg?alt=media&token=13048ffe-34e7-485c-be06-674f4cefa97a',
        mediaAltTxt: 'Indian and ethnic clothing online shop photography'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Findi%20diwali%2Fpro_diwali_3.jpg?alt=media&token=27476c25-b3d6-429d-9ca7-0a6ed750ae12',
        mediaAltTxt: 'Indian and ethnic clothing online shop photography'
      }
    ],
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Findi%20diwali%2Finditrunk_diwali_thumbnail.webp?alt=media&token=82164ddd-fd22-43cb-8c16-123eaeb0f077',
    thumbnailAltText: 'Ethnic Wear photography : Inditrunk',
    meta: {
      brand: 'Inditrunk',
      'creative producer': 'vivek s k',
      'writer and director': 'naveen tejaswi',
      cinematographer: 'darshan swaroop',
      art: 'sanskriti bist',
      editor: 'noorhanaz khatoon',
      music: 'vivan radhakrishna',
      colourist: 'siddarth sunil',
      description:
        'For Inditrunk’s “Dreaming Desi” campaign, the goal was to connect with the Indian diaspora longing for a taste of home. We aimed to evoke nostalgia with our first film, leading to the concept of “Diwali.” With a talented team of technicians and artists, we brought this vision to life.'
    }
  },
  {
    id: 13,
    category: 'products',
    title: 'Mamaearth',
    banner: {
      isVideo: false,
      bannerSrc:
        'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fmamaearth%2Fpro_mama_cover.jpg?alt=media&token=6af85f97-eb93-4b73-a09d-cb79ea67dab4',
      bannerAltTxt: 'Product Photoshoot : Mamaearth Perfume'
    },
    media: [
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fmamaearth%2Fpro_mama_1.jpg?alt=media&token=b1f7d6d7-ec2c-4b74-a712-6cd12c40ab37',
        mediaAltTxt: 'Professional photoshoot ideas for skincare : Mamaearth'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fmamaearth%2Fpro_mama_2.jpg?alt=media&token=6fe5865a-6715-48ae-ac50-57efbccffa9a',
        mediaAltTxt: 'Professional photoshoot ideas for skincare : Mamaearth'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fmamaearth%2Fpro_mama_3.jpg?alt=media&token=fcc5783e-9498-4568-97c4-a7b07e62e752',
        mediaAltTxt: 'Professional photoshoot ideas for skincare : Mamaearth'
      }
    ],
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fmamaearth%2Fmama_earth_thumbnail.webp?alt=media&token=bf878deb-0063-4541-af0d-ba5d8767d45b',
    thumbnailAltText: 'Product Photoshoot : Mamaearth Perfume',
    meta: {
      brand: 'Mamaearth',
      'photography & lighting': 'vivek s k and noorhanaz khatoon',
      'post production': 'noorhanaz khatoon',
      description:
        'This shoot turned into a creative challenge to see what we could do with limited resources. Shot entirely with natural light, we drew inspiration from Mamaearth’s subtle musky tones and elegant packaging. Our mission was to capture that natural elegance in every shot, letting the beauty of simplicity shine through.'
    }
  },
  {
    id: 14,
    category: 'products',
    title: 'Minimalist',
    banner: {
      isVideo: false,
      bannerSrc:
        'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fminimalist%2Fpro_minimalist_cover.jpg?alt=media&token=3ecf528a-9fe6-483c-8e06-79000e74c616',
      bannerAltTxt: 'Aesthetic Product Photoshoot : Minimilistic'
    },
    media: [
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fminimalist%2Fpro_minimalist_1.jpg?alt=media&token=05614604-65df-4f12-872c-2cf44cecb01d',
        mediaAltTxt: 'Professional photoshoot ideas for skincare : Minimilistic'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fminimalist%2Fpro_minimalist_2.jpg?alt=media&token=02765d8e-962b-4dd3-8df3-7607df32f515',
        mediaAltTxt: 'Professional photoshoot ideas for skincare : Minimilistic'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fminimalist%2Fpro_minimalist_3.jpg?alt=media&token=fff11f4c-bc84-4b27-93c0-eaac399b7119',
        mediaAltTxt: 'Professional photoshoot ideas : Minimilistic'
      }
    ],
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fminimalist%2Fminimalist_thumbnail.webp?alt=media&token=ed1cc5a1-1b36-4066-ac9e-08f1325017ec',
    thumbnailAltText: 'Aesthetic Product Photoshoot : Minimilistic',
    meta: {
      brand: 'Minimalist',
      'photography & lighting': 'vivek s k & noorhanaz khatoon',
      'set design': 'noorhanaz khatoon',
      'post production': 'noorhanaz khatoon',
      description:
        'For this product, our goal was to reflect the brand’s philosophy of transparency. We used natural elements to create a calm, serene look that complements the packaging. Mirrors and their reflections echoed the brand’s ideology, while focused lighting drew attention to the product in its setting.'
    }
  },
  {
    id: 15,
    category: 'products',
    title: 'Noise',
    banner: {
      isVideo: false,
      bannerSrc:
        'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fnoise%2Fpro_noise_cover.jpg?alt=media&token=a7ea0f14-a897-4cfb-b72c-b38dc2558abd',
      bannerAltTxt: 'Noise Black Watch photography'
    },
    media: [
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fnoise%2Fpro_noise_1.jpg?alt=media&token=c8cc2369-6814-4543-ab8f-3433e691483d',
        mediaAltTxt: 'Stylish wristwatch on a black background'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fnoise%2Fpro_noise_2.jpg?alt=media&token=c9dc4065-bd5e-411c-80df-f89683962479',
        mediaAltTxt: 'Professional photoshoot ideas for luxury noise smart watches '
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fnoise%2Fpro_noise_3.jpg?alt=media&token=b230f423-aef4-43e2-910b-47bf63efb465',
        mediaAltTxt: 'wristwatch showcasing the dail in a close-up shot'
      }
    ],
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fnoise%2Fnoise_thumbnail.webp?alt=media&token=64874d95-58b9-4510-aba4-ea8d2db84db5',
    thumbnailAltText: 'Noise Black Watch photography',
    meta: {
      brand: 'Noise',
      'photography & lighting': 'noorhanaz khatoon',
      'post production': 'noorhanaz khatoon',
      description:
        "When noir met noise, we got a gallery that's minimal, moody, and sleek. For a brand that upholds simplicity in its design, we wanted to keep the set design minimal, which would allow us to highlight the details of the product. "
    }
  },
  {
    id: 16,
    category: 'products',
    title: 'Plum',
    banner: {
      isVideo: false,
      bannerSrc:
        'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fplum%2Fpro_plum_cover.jpg?alt=media&token=0b7fbd84-83f4-440b-a183-38256c7b950e',
      bannerAltTxt: 'Plum Serum photoshoot : Skincare '
    },
    media: [
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fplum%2Fpro_plum_1.jpg?alt=media&token=a40c08e6-eb30-4e01-8e2e-1239a53f94f1',
        mediaAltTxt: 'Professional photoshoot ideas for skincare : Plum'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fplum%2Fpro_plum_2.jpg?alt=media&token=da254887-3a28-45a7-8bb1-a2239103a9ac',
        mediaAltTxt: 'creative skincare photoshoot in bangalore'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fplum%2Fpro_plum_3.jpg?alt=media&token=f2b40414-3677-4f3b-ba89-dcd4a2edb732',
        mediaAltTxt: 'High quality Skincare serum photography for marketing'
      }
    ],
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fplum%2Fplum_thumbnail.webp?alt=media&token=66a5b86e-2ca8-4d0b-9921-4f5d3aa0b543',
    thumbnailAltText: 'Plum Serum photoshoot : Skincare ',
    meta: {
      brand: 'Plum',
      'photography & lighting': 'vivek s k & noorhanaz khatoon',
      'set design': 'noorhanaz khatoon',
      'post production': 'noorhanaz khatoon',
      description:
        'Plum focuses on goodness that delivers, blending science with nature. To bring this to life, we created a set that incorporated natural elements to complement the product’s vibrant blues. Water, with its transparency and ability to play with light, was the perfect match.'
    }
  },
  {
    id: 17,
    title: 'Sukh',
    category: 'products',
    banner: {
      isVideo: false,
      bannerSrc:
        'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fsukh%2Fpro_sukh_cover.jpg?alt=media&token=536f6968-f3eb-4e13-a5ed-2dd7e3e79d9c',
      bannerAltTxt: 'Gift Hampher photoshoot'
    },
    media: [
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fsukh%2Fpro_sukh_1.jpg?alt=media&token=bbc336c6-8e43-4d47-bd24-edc4a34a38de',
        mediaAltTxt: 'personal care products and accessories photography'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fsukh%2Fpro_sukh_2.jpg?alt=media&token=2cd82291-9f0d-4b68-a3d0-20ce851915db',
        mediaAltTxt: 'premium gift hamper shoot near me '
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fsukh%2Fpro_sukh_3.jpg?alt=media&token=bf83ee75-93e7-4279-88b9-9b44975ec029',
        mediaAltTxt: 'Corporate Gift hamper Photoshoot'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fsukh%2Fpro_sukh_4.jpg?alt=media&token=a598a475-ed62-4519-938a-a73cbe2e186c',
        mediaAltTxt: 'Custom luxury gift hampers photography : Sukh'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fsukh%2Fpro_sukh_5.jpg?alt=media&token=c78afd5a-7530-42c4-bc41-a63551a58154',
        mediaAltTxt: 'Custom luxury gift hampers photography : Sukh'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fsukh%2Fpro_sukh_6.jpg?alt=media&token=a8f95ec2-c08d-48ed-a0c0-20e771f1eb56',
        mediaAltTxt: 'Custom luxury gift hampers photography : Sukh'
      }
    ],

    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fsukh%2Fsukh_thumbnail.webp?alt=media&token=d7580f3b-0be9-4b98-a1cf-0dd601355912',
    thumbnailAltText: 'Gift Hampher photoshoot',
    meta: {
      brand: 'Sukh',
      'photography & lighting': 'vivek s k & noorhanaz khatoon',
      'set design': 'noorhanaz khatoon',
      'post production': 'noorhanaz khatoon',
      description:
        'When Sukh approached us, we were excited to dive into a project with such a diverse range of products. For a company that wraps up memories and happiness, we aimed to keep the lighting warm and inviting. We enjoyed creating unique sets and experimenting with different moods.'
    },
    clientQuote: {
      clientName: 'Payal Menon',
      designation: 'Founder, Sukh',
      quote:
        'We had the pleasure of working with mudrost for our first product shoot when we started our gifting business. From the start, Vivek and Noor impressed us with their professionalism and dedication. <br />Despite working from morning to night, they remained patient and enthusiastic throughout. Even on a day with minimal natural light, they managed to achieve the perfect lighting in just a couple of minutes.<br />We highly recommend mudrost and would love to work with this talented team again'
    }
  },

  {
    id: 18,
    category: 'products',
    title: 'Titan - Skinn',
    banner: {
      isVideo: false,
      bannerSrc:
        'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Ftitan%2Fpro_titan_cover.jpg?alt=media&token=e6854155-143d-44ba-a58e-25e036daab83',
      bannerAltTxt: 'how to photoshoot perfume bottle : Skin'
    },
    media: [
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Ftitan%2Fpro_titan_1.jpg?alt=media&token=9b4e7198-241d-463c-8d17-ad4741d60b33',
        mediaAltTxt: 'luxury perfume "skinn" photshoot from top view '
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Ftitan%2Fpro_titan_2.jpg?alt=media&token=c8acfb68-52a0-47e9-8731-4c9b8a457a1c',
        mediaAltTxt: 'luxury perfume photoshoot'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Ftitan%2Fpro_titan_3.jpg?alt=media&token=1062171f-5cc5-464c-b610-d2e9b7388dde',
        mediaAltTxt: 'Premium perfume bottle photography'
      }
    ],
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Ftitan%2Ftitan_thumbnail.webp?alt=media&token=f3cf7501-44b0-4dd2-9d1b-7f42df18f23a',
    thumbnailAltText: 'how to photoshoot perfume bottle : Skin',
    meta: {
      brand: 'Titan - Skinn',
      'photography & lighting': 'vivek s k',
      'set design': 'vivek s k',
      'post production': 'noorhanaz khatoon',
      description:
        'This shoot was inspired by our love for the packaging design. The earthy hues and crystal top of the product made it an intriguing subject. Our aim was to simply enjoy showcasing the packaging in its best light.'
    }
  },
  {
    id: 19,
    category: 'products',
    title: 'Ryka',
    banner: {
      isVideo: false,
      bannerSrc:
        'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fryka%2Fpro_ryka_banner.jpg?alt=media&token=fc795bd5-e803-4cae-970f-180fc466409a',
      bannerAltTxt: 'Ryka :  Floral jewelry'
    },
    media: [
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fryka%2Fryka_1.jpg?alt=media&token=309651e1-8629-4dbc-aa5e-e4f9b6369b4b',
        mediaAltTxt: 'Floral Jewelry Photography Ideas'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fryka%2Fryka_2.jpg?alt=media&token=45b524b7-0778-4003-b945-75cbbf794671',
        mediaAltTxt: 'How to style jewelry photos'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fryka%2Fryka_3.jpg?alt=media&token=4c399442-e436-4c88-b179-dc099970703b',
        mediaAltTxt: 'How to shoot floral jewelery'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fryka%2Fryka_4.jpg?alt=media&token=0eb6e66b-ecac-4614-b4ee-1d3636893aa4',
        mediaAltTxt: 'Photoshoot : Floral Jewelery'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fryka%2Fryka_5.jpg?alt=media&token=0fa32e83-8bcc-498d-bfb0-effea5b4cc66',
        mediaAltTxt: 'Photoshoot : Floral Jewelery'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fryka%2Fryka_6.jpg?alt=media&token=978f60d9-2180-4760-ba25-8e9163f6dc60',
        mediaAltTxt: 'Photoshoot : Floral Jewelery'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fryka%2Fryka_7.jpg?alt=media&token=09760c46-1291-41f5-a7ba-ea483506b505',
        mediaAltTxt: 'Photoshoot : Floral Jewelery'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fryka%2Fryka_8.jpg?alt=media&token=82f6bd1a-b574-48ea-82d2-e5f61086e66f',
        mediaAltTxt: 'Photoshoot : Floral Jewelery'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fryka%2Fryka_9.jpg?alt=media&token=5b875ff8-0ff3-41c3-9f75-56dca7464ec5',
        mediaAltTxt: 'Photoshoot : Floral Jewelery'
      }
    ],
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Fryka%2Fryka_thumbnail.jpg?alt=media&token=73a46f66-deff-4c42-8287-3258f0c76a48',
    thumbnailAltText: 'Ryka :  Floral jewelry',
    meta: {
      brand: 'Ryka',
      'photography & lighting': 'vivek s k',
      'creative direction and styling': 'noorhanaz khatoon',
      'post production': 'noorhanaz khatoon',
      description:
        'When Ryka approached us, their delicate floral jewelry sparked the idea of a "Garden of Wishes." We envisioned each piece as a small, graceful wish blossoming in a serene natural setting. Our goal was to create a space where the beauty of their jewelry could quietly merge with the gentle rhythm of nature, bringing to life a place where wishes grow.'
    }
  },
  {
    id: 20,
    category: 'foodandbeverages',
    title: 'Rivaayat',
    banner: {
      isVideo: false,
      bannerSrc:
        'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Frivaayat%2Fbanner_fnb_rivaayat.jpg?alt=media&token=b9cdd812-3dd1-43e7-bad3-5f713a8a0095',
      bannerAltTxt: 'Rivaayat : Hyderabad’s culinary traditions'
    },
    media: [
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Frivaayat%2Ffnb_rivaayat_1.jpg?alt=media&token=7f85ed68-a59a-4e50-97a1-d3ab4c04d7ca',
        mediaAltTxt: 'Photos Hyderabad’s culinary traditions'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Frivaayat%2Ffnb_rivaayat_2.jpg?alt=media&token=8e660962-fdd0-4ad9-b37a-17c927e7b8ca',
        mediaAltTxt: 'Photos Hyderabad’s culinary traditions'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Frivaayat%2Ffnb_rivaayat_3.jpg?alt=media&token=687f9869-a6f7-4b5f-abde-29884c50ff7c',
        mediaAltTxt: 'Closeup shot Hyderabad’s culinary traditions'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Frivaayat%2Ffnb_rivaayat_4.jpg?alt=media&token=93d250a1-9113-4385-a536-bd2610bdf698',
        mediaAltTxt: 'Food Photography'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Frivaayat%2Ffnb_rivaayat_5.jpg?alt=media&token=5540a3fe-7162-4a72-9952-6d892fe62607',
        mediaAltTxt: 'Food Photography '
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Frivaayat%2Ffnb_rivaayat_6.jpg?alt=media&token=8a436ed5-3df7-4bfc-a806-2e8ec24edf6f',
        mediaAltTxt: 'Food Photography '
      }
    ],
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2Frivaayat%2Ffnb_rivaayat_thumbnail.jpg?alt=media&token=18911ef0-045a-40a4-8cb9-87ef855b64f2',
    thumbnailAltText: 'Rivaayat : Hyderabad’s culinary traditions',
    meta: {
      brand: 'Rivaayat',
      'photography & lighting': 'vivek s k',
      'creative direction and styling': 'noorhanaz khatoon',
      'post production': 'vivek s k and noorhanaz khatoon',
      description:
        'Rivaayat is a quiet tribute to Hyderabad’s culinary traditions. The photos focus on the simple elegance of Nawabi flavors, with vintage-inspired settings that let the food speak for itself. The aim was to create timeless images that reflect the true essence of the menu.'
    }
  },
  {
    id: 21,
    category: 'foodandbeverages',
    title: 'Anand Mullu Murukku',
    banner: {
      isVideo: false,
      bannerSrc:
        'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2FAnand%20Mullu%20Muruku%2Fbanner_fnb_mullu_muruku.jpg?alt=media&token=f7f4d497-78dc-4140-a666-1187b2781dd8',
      bannerAltTxt: 'Indian Sweet photography : Anand Mullu Murukku'
    },
    media: [
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2FAnand%20Mullu%20Muruku%2Ffnb_mullu_muruku_1.jpg?alt=media&token=618c88b1-5988-4490-876b-a5482934fafd',
        mediaAltTxt: 'premium Indian sweets photography'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2FAnand%20Mullu%20Muruku%2Ffnb_mullu_muruku_2.jpg?alt=media&token=f30b65ba-e39d-49f2-8085-2057b51a588e',
        mediaAltTxt: 'Indian Sweets Photography Images'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2FAnand%20Mullu%20Muruku%2Ffnb_mullu_muruku_3.jpg?alt=media&token=8805053e-fe4f-400d-a043-acaedc85cdd9',
        mediaAltTxt: 'Indian Sweet Closeup Images'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2FAnand%20Mullu%20Muruku%2Ffnb_mullu_muruku_4.jpg?alt=media&token=3afef442-7908-437d-995e-a6c7d2f9700b',
        mediaAltTxt: 'Indian Sweet Closeup Images'
      }
    ],
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2FAnand%20Mullu%20Muruku%2Fmuruku_anand_thumbnail.jpg?alt=media&token=bec417ab-0d80-4393-a2f1-25b53eda9274',
    thumbnailAltText: 'Indian Sweet photography : Anand Mullu Murukku',
    meta: {
      brand: 'Anand Sweets',
      'photography & lighting': 'vivek s k',
      'creative direction and styling': 'noorhanaz khatoon',
      'post production': 'vivek s k',
      description:
        'For a brand known for premium Indian sweets, it was essential to preserve their elegance and traditional essence. With the product, Mullu Murukku, we aimed to evoke nostalgia by capturing the warmth of an evening coffee scene.'
    }
  },
  {
    id: 22,
    category: 'foodandbeverages',
    title: 'Anand Almond Pista',
    banner: {
      isVideo: false,
      bannerSrc:
        'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2FAnand%20Almond%20Pista%2Falmond_pista_banner.jpg?alt=media&token=838217e7-1ae9-42f7-9119-d8bcef3b90dd',
      bannerAltTxt: 'premium snacking photoshoots'
    },
    media: [
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2FAnand%20Almond%20Pista%2Fanand_almond_pista_4.jpg?alt=media&token=95cb5221-ecf1-4cde-9b25-e37402983ff1',
        mediaAltTxt: 'premium snacking product photoshoot ideas'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2FAnand%20Almond%20Pista%2Fanand_almond_pista_5.jpg?alt=media&token=ed5601cb-162f-4f9a-8b5c-ed75c54af163',
        mediaAltTxt: 'premium snacking product photoshoot ideas'
      },
      {
        isVideo: false,
        src: 'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2FAnand%20Almond%20Pista%2Fanand_almond_pista_3.jpg?alt=media&token=876976d1-aca6-4e75-8b1c-61aa05a5b95c',
        mediaAltTxt: 'premium snacking product photoshoot ideas'
      }
    ],
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/bildstudio-e98d8.appspot.com/o/products%2FAnand%20Almond%20Pista%2Ffnb_almondpista_thumbnail%20(1).jpg?alt=media&token=287342f1-231c-4659-84f6-17928ab192b4',
    thumbnailAltText: 'premium snacking photoshoots',
    meta: {
      brand: 'Anand Sweets',
      'photography & lighting': 'vivek s k',
      'creative direction and styling': 'noorhanaz khatoon',
      'post production': 'noorhanaz khatoon and vivek s k',
      description:
        "Anand takes pride in delivering the taste of royal India, with recipes crafted for a modern audience. To reflect this essence and highlight their premium snacking range, we created a refined yet simple 'chai time' sequence featuring Anand's almond pista cookies."
    }
  }
];
